// font libraries
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$rp-primary-color: #00a39d;
$rp-primary-dark: #006061;
$rp-primary-red: #e02726;
$rp-dark-gray: #42526e;
$rp-med-gray: #818181;
// #E95051
// #5AC7C0

$seondary-color: #2a0061;
$seondary-heading: #0e7172;
$light: #ffffff;
$dark: #000000;
$text-color: #333333;
$heading-color: #1a1a1a;
$card-heading: #2c4143;

$montserrat: 'montserrat', sans-serif;

//
/* 404 page colors */
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: $montserrat;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.3px;
}

p {
  margin-bottom: 10px;
  line-height: 20px;
  font-size: 15px;
  color: $rp-dark-gray;
}

a {
  text-decoration: none;
  font-size: 13px;
  color: $rp-primary-color;
  transition: all 0.3s ease;
  cursor: pointer;
  display: inline-block;

  &:hover {
    color: #01948a;
  }
}

i {
  font-size: 1.2rem;
  transition: all ease 0.3s;
}

img {
  width: 100%;
}

li {
  list-style: none;
}

body {
  background: #f7f7f7;
}

section {
  margin-bottom: 4.5rem;
}

header {
  margin-bottom: 4.5rem;
}

.bi::before,
[class^='bi-']::before,
[class*=' bi-']::before {
  line-height: 0.5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
}

.form-check {
  label,
  input[type='radio']:not(old),
  input[type='checkbox']:not(old) {
    cursor: pointer;
  }
}

.form-check-label {
  vertical-align: text-top;
}

::selection {
  color: $light;
  background: $rp-primary-color;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-moz-text-control-preview {
  appearance: none;
  height: 1rem;
  width: 1rem;
  background: url('https://icons.getbootstrap.com/assets/icons/x-circle.svg')
    no-repeat center center/cover;
  cursor: pointer;
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  display: block;
}

fieldset {
  padding: 0.5rem 0.8rem 0.625rem 0.8rem;
  border: 2px $rp-primary-color solid;
  border-radius: 8px;
}

legend {
  float: unset;
  width: unset;
  font-size: 18px;
  font-weight: 600;
  color: $rp-primary-dark;
}

// input[type='search']::-ms-clear {
//   appearance: auto;
// }
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1200px;
  }
}

.navbar {
  align-items: unset;
}

.modal-footer {
  justify-content: start;
  padding-bottom: 0;

  & > * {
    margin: unset;
    margin-right: 20px;
  }
}

.container {
  max-width: 1150px !important;
}

.container_2 {
  max-width: 1300px !important;
}

.modal-open {
  padding-right: unset !important;
}

.w-30 {
  width: 30%;
}

// .grayscale {
//   filter: grayscale(1);
// }

.radial-graph .fusioncharts-container svg text[transform] {
  font-weight: 600;
  font-size: 14px !important;
}

.radial-graph .fusioncharts-container svg path {
  pointer-events: none !important;
}

li {
  .badge {
    color: $rp-primary-color;

    &:hover {
      color: $light;
      background-color: $rp-primary-color;
    }
  }
}

.list-disc {
  li {
    list-style: disc;
  }
}

.list-circle {
  li {
    list-style: circle;
  }
}

.list-decimal {
  li {
    list-style: decimal;
  }
}

.z-1 {
  z-index: 1;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fs-7 {
  font-size: 15px;
}

.p-hed {
  font-weight: bold;
  color: $rp-primary-color;
}

.separator {
  width: 2px;
  background-color: #b3b3b3;
  height: 50px;
  margin: 0 15px;

  &-primary {
    background-color: $rp-primary-color;
    height: 4px;
  }
}

.asterisk:after {
  content: ' *';
  color: #dc3545;
}

.cursor-p {
  cursor: pointer;
}

.cursor-t {
  cursor: text !important;
}

.fixed-top {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.fixed-bottom {
  left: 3%;
  right: 3%;
  z-index: unset;
}

.fixed-bottom_2 {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index: 1030;
}

.search-cancel-button {
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
  pointer-events: none;
}

.text-justify {
  text-align: justify;
}

.show {
  display: block !important;
}

.form-select {
  // background-color: #ecf7f7;
  background-color: #e4fcfb;
  border-radius: 6px;
  color: $rp-primary-color;
  font-size: 15px;
  letter-spacing: 0.3px;
  height: 40px;
  width: 100%;
  border: 1px solid $rp-primary-color;
}

.form-select:focus {
  border-color: unset;
  outline: unset;
  box-shadow: none;
}

.box_shadow {
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
}

.rp-primary-dark-g {
  color: $rp-primary-dark !important;
}

.rp-dark-gray {
  color: $rp-dark-gray !important;
}

.rp-med-gray {
  color: $rp-med-gray !important;
}

.rp-primary-dark {
  background-color: #036b6c !important;
}

.rp-primary-dark-c {
  color: #036b6c;
}

.rp-primary-red {
  background-color: #e02726 !important;
}

.rp-primary-red-color {
  color: $rp-primary-red !important;
}

.primary-gradient {
  background: linear-gradient(
    104.76deg,
    #00696a 2.28%,
    rgba(0, 105, 106, 0.59) 168.34%
  );
}

.exercise-placeholder {
  background: url('../images/placeholder/exercise.jpg') no-repeat center
    center/cover;
}

.food_diet-placeholder {
  background: url('../images/placeholder/food_diet.jpg') no-repeat center
    center/cover;
}

.good_habit-placeholder {
  background: url('../images/placeholder/good_habits.jpg') no-repeat center
    center/cover;
}

.stress_anxiety-placeholder {
  background: url('../images/placeholder/stress_anxiety.jpg') no-repeat center
    center/cover;
}

.focus_concentration-placeholder {
  background: url('../images/placeholder/focus_concentration.jpg') no-repeat
    center center/cover;
}

.strenght_insipiration-placeholder {
  background: url('../images/placeholder/strenght_insipiration.jpg') no-repeat
    center center/cover;
}

.rest_sleep-placeholder {
  background: url('../images/placeholder/rest_sleep.jpg') no-repeat center
    center/cover;
}

.gratitude_kindness-placeholder {
  background: url('../images/placeholder/gratitude_kindness.jpg') no-repeat
    center center/cover;
}

.brain_work-placeholder {
  background: url('../images/placeholder/brain_work_exercise.jpg') no-repeat
    center center/cover;
}

.good_cheer-placeholder {
  background: url('../images/placeholder/simple_good_cheer.jpg') no-repeat
    center center/cover;
}

// .active {
//   background-color: $rp-primary-color;
//   color: $light;
// }

.primary-color {
  color: $rp-primary-color !important;
}

.secondary-color {
  color: $seondary-color;
}

.color-white {
  color: $light;
}

.bg-primary {
  background-color: $rp-primary-color !important;
}

.bg-white {
  background-color: $light !important;
  background: $light;
}

.card-heading {
  color: $card-heading;
}

.secondary-heading {
  color: $seondary-heading;
}

.br-6 {
  border-radius: 6px;
}

.br-8 {
  border-radius: 8px;
}

.br-12 {
  border-radius: 12px !important;
}

.br-24 {
  border-radius: 24px;
}

.br-top-0 {
  border-top-left-radius: 0 !important;
}

.end-date {
  color: #e02726;
  font-weight: 600;
}

.minimal-color {
  color: #38847f !important;
}

.moderate-color {
  color: #706562 !important;
}

.moderate-bg-color {
  background-color: #706562 !important;
}

.chornic-color {
  color: #a84644 !important;
}

.carousel {
  margin: 0 40px;
}

.section-titel {
  color: #006061;
}

.about-sub-hed {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: $rp-primary-dark;
}

.section-sub-heading {
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 1.3px;
  margin-bottom: 1.5rem;
}

.section-heading {
  font-size: 43px;
  line-height: 49px;
  letter-spacing: 1.3px;
  font-weight: 700;
}

.about .section-sub-heading {
  font-size: 23px;
  margin: 17px 0;
  line-height: 24px;
  font-weight: 400;
}

.card-heading-dark {
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0.3px;
  color: $card-heading;
}

.card-heading-light {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.3px;
  color: $light;
}

.card-heading-sec-dark {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0.3px;
  color: #42526e;
}

.dots-styles,
.dots-styles-left {
  @include dotStyle('../images/dot-bottom-right.svg');
  bottom: 0;
  right: 0;
  height: 60%;
  width: 67%;
}

.dots-styles-left {
  @include dotStyle('../images/dot-left.svg');
  left: 0;
}

.dots-styles-top,
.dots-styles-top-left,
.dots-styles-top-right {
  @include dotStyle('../images/dot-top.svg');
  height: 100%;
  width: 30%;
  top: 0;
  right: 0;
}

.dots-styles-top-left {
  left: 0;
  right: unset;
}

.dots-styles-top-right {
  height: 37%;
  width: 40%;
}

.dots-style-2 {
  position: absolute;
  @include dotStyle('../images/dot-style-2.svg');
  height: 90%;
  width: 50%;
  bottom: 0;
  right: 0;
}

.dots-style-2.left {
  left: 0;
  transform: rotateY(190deg);
}

.link_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.like-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.like-btn::before,
.liked::before {
  content: '\F417';
  font-family: bootstrap-icons !important;
  font-size: 1.5rem;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid;
  border-radius: 50%;
  line-height: 0px;
  padding-top: 3px;
}

// .like-btn:hover::before {
//   content: '\F415';
//   color: $light;
// }
// .liked:hover::before {
//   color: #ff5959;
// }
.liked::before {
  content: '\F415';
  color: #ff5959;
  // background-color: #ff5959;
}

.load-more-recommended {
  margin-top: -1rem;
}

.load-button {
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: end;

  span,
  a {
    text-decoration: underline;
    color: $rp-dark-gray;
    font-size: 15px;
  }

  i {
    color: $rp-primary-color;
    margin-left: 5px;
    text-decoration: none;
  }
}

.table tr td {
  color: #40464c;
}

.table > tbody > tr,
.table > thead > tr {
  height: 50px;
  vertical-align: middle;
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: none;
  background-color: #c6f6f5;
  color: #00a39d;
  cursor: pointer;
}

.table-active > * {
  --bs-table-accent-bg: none;
  background-color: #c6f6f5 !important;
  color: #00a39d !important;
}

// .extend-btn {
//   cursor: pointer;
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
//   padding: 10px;
//   border-radius: 6px;
//   i {
//     font-size: 18px;
//     color: $rp-primary-color;
//   }
// }
.hr-line {
  width: 100%;
  margin-top: 6px;
  height: 2px;
  background: $rp-primary-color;
}

.modal {
  .modal-body {
    @include scrollBar;

    &::-webkit-scrollbar-track {
      background: #f8f8f8;
    }

    p {
      color: #000000;
      line-height: 1.4rem;
    }
  }
}

.modal-content {
  border: none;
  padding-bottom: 26px;
}

.modal-body {
  padding-bottom: 0;
}

.video-modal {
  .modal-header {
    margin-bottom: unset;
  }
}

.intro-video {
  .modal-content {
    padding-bottom: 0;
  }
}

.visible {
  visibility: visible !important;
}

ol li {
  font-size: 15px;
  line-height: 18px;
}

.list-style-none li {
  list-style: none !important;
}

.num-li {
  font-variant-numeric: tabular-nums;
}

.modified-ol li {
  list-style: lower-alpha;
  margin-bottom: 16px;
}

.ul_disc li {
  list-style: disc;
  margin-bottom: 4px;
}

.main_content {
  .ul_disc li,
  .list-decimal li {
    font-size: 15px;
    color: $rp-dark-gray;
  }
}

.upper-alpha li {
  list-style: upper-alpha;
}

.lower-alpha li {
  list-style: lower-alpha !important;
}

.upper-alpha li {
  margin-bottom: 10px;
}

.modified-ol li {
  font-size: 17px;
  font-weight: 200;
  line-height: 25px;
}

.select-company {
  background-color: $light;
  color: $dark;
}

.tags {
  @include tags($rp-primary-dark);
}

.min-h-480 {
  min-height: 480px;
}

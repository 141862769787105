@mixin alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin spaceBetween {
  @include alignCenter;
  justify-content: space-between;
}

@mixin spaceAround {
  @include alignCenter;
  justify-content: space-around;
}

@mixin tags($color) {
  background-color: #c6f6f5;
  color: $color;
  border-radius: 44px;
  padding: 5px 10px;
}

@mixin primaryButtons($color, $tColor) {
  text-align: center;
  background: $color;
  border-radius: 8px;
  color: $tColor;
  min-width: 170px;
  height: 40px;
  border: 1px solid $color;
  line-height: 21px;
  letter-spacing: 0.3px;
  font-size: 15px;
  // padding: 10px 24px;
}

@mixin dotStyle($url) {
  position: absolute;
  background: url($url) no-repeat center center/cover;
}

@mixin scrollBar {
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $light;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a1a1a1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
  }
}

@mixin npsPill($color, $bgColor) {
  border-radius: 24px;
  color: $color;
  background-color: $bgColor;
  line-height: 26px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 16px;

  span {
    color: $color;
  }

  b {
    color: $color;
  }
}

//
@mixin instructor {
  .instructor-container {
    display: flex;
    margin-bottom: 15px;

    .instructor {
      border: 2px solid $light;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      overflow: hidden;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@mixin eventDate($color) {
  .event-date {
    font-weight: 500;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    span {
      color: $color;
    }

    i {
      font-size: 17px;
      color: $color;
      margin-right: 5px;
    }

    .separator {
      width: 1px;
      height: 25px;
      background-color: $color;
    }
  }
}

@mixin eventMode($color) {
  .event-mode {
    color: $color;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 15px;
  }
}

// assesment tabs
@mixin assesTabs {
  .btn-assment-month {
    background-color: $light;
    border-radius: 8px;
    height: 65px;
    width: 150px;
    border: 2px solid transparent;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    span {
      color: $dark;
      font-size: 14px;
      display: block;
    }
  }
}

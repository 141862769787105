@import 'spacing';
@import 'mixin';
@import 'utilities';
@import 'button';
@import '404';
@import 'preloder-animation';

// Login Page
.login-container {
  padding: 1rem 3rem 0;
}

.login-bg {
  background: url('../images/login-bg.webp') no-repeat left center/cover;
  min-height: 100vh;
}

.notificaton {
  cursor: pointer;
}

.notificaton .dot {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $rp-primary-red;
  top: 0;
  right: 0;
  z-index: 1;
}

.notificaton i {
  font-size: 22px;
}

.profile-icon {
  width: 50px;
  height: 50px;
  background-color: $rp-primary-dark;
  border-radius: 50%;
  @include alignCenter;
  font-weight: 600;
  font-size: 22px;
  color: $light;
  letter-spacing: 2px;
}

.user-options {
  display: flex;
  justify-content: end;
  align-items: center;
}

.profile-menu {
  i {
    font-size: 13px;
  }
}

.wlcm-note {
  h6 {
    font-size: 1.25rem;
    margin: 18px 0px;
  }

  p {
    color: #6b778c;
    font-size: 13px;
    letter-spacing: 0.3px;
  }
}

.company-logo {
  @include spaceBetween;
}

.form-control:focus {
  border-color: $rp-primary-color;
  box-shadow: none;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked {
  background-color: $rp-primary-color;
  border-color: $rp-primary-color;
}

.form-switch .form-check-input {
  width: 30px;
  height: 17px;
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='rgba%280, 0, 0, 0.25' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='6'/%3E%3C/svg%3E");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='6'/%3E%3C/svg%3E");
}

.login-box {
  .form-control {
    font-size: 0.93rem;
    padding-left: 40px;
    border-radius: 6px;
    border-color: transparent;
    background-color: #f2f2f2;
    height: 48px;
  }

  .id_password {
    padding-right: 30px;
  }

  .togglePassword {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }

  .remember-me {
    .form-check-label {
      color: $text-color;
      font-size: 13px;
    }

    .form-check-input {
      cursor: pointer;
    }
  }

  #forgot_field {
    display: none;
  }

  .bck-log,
  .forgot-pw {
    cursor: pointer;
    font-size: 13px;
    color: #6b778c;
  }
}

.form-check-input:focus {
  border-color: #bfbfbf;
}

.form-check-input:checked {
  border-color: $rp-primary-color;
}

.hide {
  display: none !important;
}

#id_password {
  padding-right: 40px;
}

#togglePassword {
  margin-left: -30px;
  cursor: pointer;
}

.footer {
  .copyright {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .email {
      .rp-i-icon {
        width: 20px;
      }

      a {
        font-size: 12px;
        display: flex;
        align-items: center;

        i {
          color: #e02726;
          font-size: 18px;
        }
      }
    }
  }

  .copy {
    font-size: 12px;
  }
}

.version {
  font-size: 12px;
}

.qpart2 {
  display: none;
}

// 1st

// header ui
.header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 5px 2rem;

  .profile-mob {
    display: none;
  }

  .great {
    .user-name {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }

    p {
      font-size: 14px;
      color: #000;
    }
  }

  .dropdown-toggler {
    i {
      font-size: 18px;
    }
  }
}

.mob-toggler {
  display: none;
}

.head-tabs {
  display: flex;
  justify-content: center;

  .head-tab {
    background-color: #f5f5f5;
    border-radius: 8px;
    min-height: 45.6px;
    min-width: 180px;
    color: $dark;
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    a {
      color: $dark;
      font-size: 0.9rem;

      // only for html design
      min-width: 180px;
      padding: 0.75rem 0;
    }
  }

  .active {
    background-color: $rp-primary-color;
    color: $light;

    a {
      color: $light;
    }
  }
}

.assment-tab {
  display: flex;
  align-items: center;
}

.assessment-tabs-group {
  @include assesTabs;

  .btn-assment-month.next-mnth {
    span {
      color: $rp-med-gray;
    }

    cursor: not-allowed;
  }

  .month-active {
    border: 2px solid $rp-primary-color;

    span {
      color: $rp-primary-color;
      font-weight: 500;
    }
  }
}

.about {
  display: flex;

  .sub-hed-p {
    color: #000000;
  }

  p {
    font-weight: 700;
  }

  h1 {
    color: $rp-primary-dark;
  }

  h2 {
    color: #575757;
  }
}

.info {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: $rp-dark-gray;
  opacity: 0.75;

  i {
    font-size: 13px;
    color: $rp-dark-gray;
    opacity: 0.75;
  }
}

.resiliency-factor,
.score-card {
  height: 546px;
  border-radius: 12px;
  background-color: $light;
  padding: 1rem 2rem;
  position: relative;
}

.about-card {
  position: relative;
  min-height: 404px;

  border-radius: 12px;
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
  background: linear-gradient(160.62deg, #c3dfde -33.37%, #fbefe5 145.53%);
  padding: 2rem;
  // margin-bottom : 2rem;

  p {
    color: #42526e;
    font-family: 'montserrat', sans-serif;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    position: relative;
  }
}

.about-card.card__face--back {
  p {
    font-size: 22px;
    line-height: 26px;
  }
}

.sub-titel {
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  font-family: 'montserrat', sans-serif;
  color: $rp-primary-dark;
}

.nutritional {
  background: linear-gradient(
    155.67deg,
    #ecb676 -14.24%,
    rgba(229, 214, 162, 0.38) 118.55%
  );
}

.lifestyle {
  background: linear-gradient(
    155.67deg,
    #76ece5 -14.24%,
    rgba(229, 214, 162, 0.38) 118.55%
  );
}

.lifestyle-style-2 {
  background: linear-gradient(160.62deg, #e9f3f3 -33.37%, #9bc4cb 145.53%);
}

.mindfulness {
  background: linear-gradient(
    155.67deg,
    #f7c0ec -14.24%,
    rgba(167, 189, 234, 0.38) 118.55%
  );
}

.mindfulness-style-2 {
  background: linear-gradient(160.62deg, #c1ebe8 -33.37%, #d1c3e4 145.53%);
  min-height: 450px;
}

.card-min-height {
  min-height: 569px;
}

.gift-box {
  padding: 2rem 1rem;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.06), 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 12px;
}

.gift-thumbnail {
  text-align: center;

  img {
    width: 90px;
    border: 1px solid $rp-primary-color;
    cursor: pointer;
  }
}

a.dropdown-toggler {
  display: block;
}

.mob-toggler {
  #nav-icon3 {
    width: 17px;
    height: 19px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #333;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }

  #nav-icon3 span:nth-child(2),
  #nav-icon3 span:nth-child(3) {
    top: 6px;
  }

  #nav-icon3 span:nth-child(4) {
    top: 12px;
  }

  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }

  #nav-icon3.open span:nth-child(2) {
    transform: rotate(45deg);
  }

  #nav-icon3.open span:nth-child(3) {
    transform: rotate(-45deg);
  }

  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

.notification-popup {
  position: absolute;
  right: -20px;
  top: 60px;
  z-index: 2;
  background: $light;
  padding: 20px;
  border-radius: 12px;
  width: 420px;
  display: none;
  animation: slideInDown;
  animation-duration: 0.5s;

  .noti-icon {
    color: $rp-dark-gray;
    margin-bottom: 5px;
    font-size: 14px;

    i {
      color: $rp-dark-gray;
      font-size: 14px;
    }
  }
}

.noti-titel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.28);
  margin-bottom: 10px;
}

.active-dot {
  width: 10px;
  height: 10px;
  background: #006061;
  border-radius: 50%;
}

.notification-div {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  // padding-top: unset;
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
}

.notification-cnt {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}

.noti-action {
  display: flex;
  justify-content: space-between;

  .btn-rp-primary,
  .btn {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    min-width: 130px;
  }

  .dismiss {
    &:hover {
      color: $rp-primary-dark;
    }
  }
}

.notification-div:last-child {
  margin-bottom: unset;
}

.carousel-indicators {
  margin-bottom: unset;
  bottom: -30px;
  position: absolute;
  border-top: unset;
  border-bottom: unset;
  right: unset;
  left: unset;
  z-index: 2;
  display: unset;
  margin-right: unset;
  margin-left: unset;
  width: 100%;
  text-align: center;

  [data-bs-target] {
    background-color: $rp-dark-gray;
    width: 17px;
    height: 5px;
    border-radius: 5px;
    opacity: 0.25;
    border-top: unset;
    border-bottom: unset;
  }

  .active[data-bs-target] {
    background-color: $rp-primary-color;
    width: 40px;
    height: 5px;
    border-radius: 5px;
    opacity: 1;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.28809 12L14.2981 18.01L15.7121 16.596L11.1121 11.996L15.7121 7.39601L14.2981 5.99001L8.28809 12Z' fill='%2300A39D'%3E%3C/path%3E%3C/svg%3E%0A");
}

.carousel-control-prev {
  left: -65px;
}

.carousel-control-next {
  right: -65px;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7131 12L9.70209 5.99001L8.28809 7.40401L12.8881 12.004L8.28809 16.597L9.70209 18.011L15.7131 12Z' fill='%2300A39D'%3E%3C/path%3E%3C/svg%3E%0A");
}

.sidenav {
  position: absolute;
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: end;
  right: -60px;
  top: 20px;

  .active {
    background-color: $rp-primary-color !important;
    color: $light !important;
    box-shadow: unset;
  }
}

.sidenav .lang-btn {
  transition: 0.3s;
  padding: 5px;
  min-width: 80px;
  text-decoration: none;
  font-size: 15px;
  color: $rp-primary-color;
  transition: all ease 0.3s;
  border-radius: 5px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.17);
  margin-bottom: 10px;
  background-color: $light;
}

.questionnaire {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 4rem 4.5rem 2rem;
  min-height: 656px;

  .questions {
    margin-bottom: 3.5rem;

    .question {
      margin-bottom: 1.5rem;

      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.3px;
        color: #006060;
      }
    }
  }

  .options {
    .btn-group {
      width: 100%;

      .active {
        background-color: #d4dbda;
      }
    }
  }
}

.progress {
  height: 10px;
  border-radius: 6px;
}

.progress-bar {
  border-radius: 6px;
  background-color: $rp-primary-color;
}

.test-progress {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 2rem 6rem;

  span {
    color: $light;
    font-weight: 400;
  }

  .progress {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .progress-bar {
    background-color: $light;
  }
}

.daily-link-style-2 {
  display: flex;
  padding: 2rem;

  .daily-link-img {
    padding-left: 10rem;
  }
}

// .daily-link-card {
//   border-radius: 12px;
//   position: relative;
//   min-height: 450px;
//   overflow: hidden;
//   background: $light;
//   box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);

//   .music-player {
//     color: $light;
//     position: relative;

//     .player-slider {
//       position: absolute;
//       background: #fff;
//       padding: 6px;
//       border-radius: 50%;
//       top: -3.5px;
//       box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//       left: 55%;
//       cursor: pointer;
//     }
//   }

//   .progress {
//     height: 0.3rem;

//     .progress-bar {
//       background-color: $rp-primary-color;
//       width: 55%;
//     }
//   }
//   .music-titel {
//     h5 {
//       font-weight: 600;
//       font-size: 18px;
//       line-height: 18px;
//       letter-spacing: 0.3px;
//       color: #2c4143;
//     }

//     p {
//       font-family: 'montserrat', sans-serif;
//       font-size: 12px;
//       line-height: 14px;
//       letter-spacing: 0.3px;
//       color: #96a0a1;
//     }
//   }
// }

.cnt-source {
  @include spaceBetween;
  position: absolute;
  // right: 20px;
  bottom: 5px;
  width: 83%;

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 30px;
  }

  p {
    color: $rp-dark-gray;
    font-weight: 500;
  }
}

// .squre {
//   height: 274px;
//   width: 274px;
//   background: linear-gradient(254.74deg, #000000 -34.67%, #4900a8 140.1%);
//   left: -10%;
//   top: -20%;
//   position: absolute;
//   transform: rotate(-45deg);
//   position: absolute;
// }
// .circle {
//   height: 370px;
//   width: 346px;
//   border-radius: 50%;
//   background: linear-gradient(
//     270.27deg,
//     rgba(0, 168, 157, 0.77) 0.29%,
//     rgba(0, 168, 157, 0) 246.7%
//   );
//   left: 50%;
//   top: -30%;
//   position: absolute;
// }
// .card-content {
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   padding: 0 2rem 2rem;
// }
// .card-titel {
//   position: absolute;
//   color: $light;
//   font-weight: 700;
//   letter-spacing: 0.3px;
//   font-size: 13px;
// }

// .section-sub-titel {
//   font-weight: 700;
//   letter-spacing: 0.3px;
//   font-size: 13px;
//   margin-bottom: 2rem;
// }

.video-card {
  height: 230px;
  border-radius: 12px 12px 0 0;
  padding: 1rem;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-size: 100% 100% !important;

  .video-sub-head {
    color: $rp-primary-dark;
    font-size: 13px;
    font-weight: bold;
    font-family: 'montserrat', sans-serif;
    letter-spacing: 0.3px;

    i {
      color: $light;
      font-size: 13px;
      margin-left: 5px;
    }
  }

  // .music-head {
  //   font-family: "Qwigley", cursive;
  //   font-size: 69px;
  //   color: $light;
  //   text-align: center;
  //   font-weight: 400;
  //   margin-top: 25%;
  // }
  // .music-discription {
  //   color: #fff;
  //   text-align: right;
  //   font-family: "Montserrat", sans-serif;
  //   letter-spacing: 2.3px;
  //   text-transform: uppercase;
  //   font-size: 14px;
  // }
}

.video-tags {
  margin-top: 0 10px;

  .tags {
    @include tags($rp-primary-color);
    font-size: 13px;
  }
}

.video-card-play i {
  color: $rp-primary-color;
  font-size: 2.5rem;
}

.video-card-play {
  background-color: $rp-primary-color;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 1.5rem;
    line-height: 0;
  }
}

.music-card-play {
  position: unset;
}

.video-card-discription {
  position: relative;
  background: #fff;
  padding: 10px 30px;
  border-radius: 0 0 12px 12px;
  min-height: 180px;

  h6 {
    font-size: 18px;
  }

  p {
    margin-bottom: 5px;
  }
}

.products {
  display: flex;
}

// supplement page
.supplement-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3rem;
}

.doc-profile {
  background-color: $light;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;

  .p-text {
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 22px;
    margin-bottom: 1.5rem;
    color: #818181;
  }

  .doc-name {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }
}

.product-card {
  padding-top: 0;
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
  min-height: 400px;
  border-radius: 12px;
  background-color: $light !important;
  position: relative;

  .product-img {
    position: relative;
    width: 250px;
    margin: auto;

    img {
      cursor: zoom-in;
    }
  }

  .product-cnt {
    position: absolute;
    bottom: 10px;
    width: 87%;

    .product-sub-titel {
      color: $rp-primary-color;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 15px;

      i {
        font-size: 14px;
        margin-left: 10px;
        color: $rp-primary-color;
      }
    }

    .product-titel {
      h6 {
        font-size: 18px;
        line-height: 18px;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
      }
    }

    .product-discription {
      p {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        margin-bottom: 15px;
      }
    }

    .product-shop {
      display: flex;
      justify-content: space-between;
    }

    .btn-rp-secondary {
      min-width: 130px;
    }
  }
}

.product-card-main {
  border-radius: 12px;
  background: $light;
  min-height: 400px;

  .card-heading-light {
    font-size: 20px;
    line-height: 24px;
  }
}

.dosage-action {
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 25px;
  width: 90%;

  .dosage {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;

    &:hover {
      color: $rp-primary-dark;
    }

    &:active {
      color: $light;
    }
  }
}

.prescription {
  img {
    width: 35px;
    margin-right: 10px;
  }
}

.product-about-card {
  position: relative;
  border-radius: 12px;
  background: linear-gradient(
    104.76deg,
    #00696a 2.28%,
    rgba(0, 105, 106, 0.59) 168.34%
  );
  min-height: 400px;
  padding: 2rem;

  .sub-titel {
    color: $light;
  }

  .card-heading-light {
    font-size: 23px;
  }
}

.impact-card,
.impact-card-green {
  position: relative;
  border-radius: 12px;
  background: $light;
  min-height: 450px;
  padding: 2rem;
  min-height: 485px;

  .sub-titel {
    color: $rp-primary-color;
  }

  .card-heading {
    color: $rp-primary-dark;
    font-size: 24px;
    font-weight: 500;
  }

  .impt {
    font-size: 35px;
    color: $rp-primary-dark;
  }

  .icon {
    width: 90px;
    margin: 30px 0;
  }

  .card-info {
    color: #00696a;
    font-size: 20px;
    line-height: 24px;
  }

  .asumption-div {
    color: $rp-primary-red;
    font-size: 14px;
    position: absolute;
    bottom: 20px;
  }

  .increse {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;

    i {
      color: #00696a;
    }
  }

  .decrease {
    i {
      color: #e02726;
    }
  }

  .value {
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: $rp-primary-color;
  }

  .rate {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.3px;
    color: $rp-primary-color;
  }

  .value.dark,
  .rate.dark {
    color: $rp-primary-dark;
  }
}

// .compay-factor {
//   background-color: $light;
//   border-radius: 12px;
//   padding: 20px;
//   min-height: 425px;

//   .progress {
//     height: 0.5rem;

//     .progress-bar {
//       border-right: 1px solid $light;
//     }
//     .progress-bar:last-child {
//       border-right: unset;
//     }
//   }
//   .card-heading-sec-dark {
//     font-weight: 600;
//     color: $rp-primary-dark;
//   }
//   .scaling-factor {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 10px;

//     span {
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 14px;
//       letter-spacing: 0.3px;
//       color: $rp-primary-dark;
//     }
//   }
//   .impt-dark,
//   .impt-red {
//     font-weight: 500;
//     font-size: 48px;
//     line-height: 22px;
//     letter-spacing: 0.3px;

//     /* RP Dark Green */

//     color: $rp-primary-dark;
//   }
//   .impt-red {
//     color: $rp-primary-red;
//   }

//   .resource {
//     font-size: 11px;
//     line-height: 14px;
//     color: $rp-dark-gray;
//   }
// }

.impact-card-green {
  background-color: #d8eceb;
}

.icon-list {
  position: relative;

  ul {
    li {
      display: flex;
      align-items: center;
    }
  }
}

.positive-impact {
  .icon-list {
    ul {
      li {
        display: flex;
        align-items: center;
      }
    }

    .icon {
      width: 35px;
      margin-right: 1rem;
    }

    .li-cnt p {
      color: $rp-primary-dark;
      font-size: 18px;
    }
  }
}

.radial-chart {
  z-index: 1;
  @include alignCenter;
  position: relative;
}

.circle-pill {
  display: flex;
  position: relative;
  justify-content: space-between;

  .pill-badge {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 4rem;
    z-index: 1;
    color: $light;

    span {
      color: #fff;
    }
  }

  .hr-behind-card {
    background-color: $rp-primary-dark;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
  }
}

// .noti-box {
//   box-shadow: 0px 8px 12px rgba(9, 30, 66, 0.02),
//     0px 0px 1px rgba(9, 30, 66, 0.31);
//   border-radius: 8px;

//   .noti-img img {
//     border-top-left-radius: 12px;
//     border-bottom-left-radius: 12px;
//   }
//   .noti-cnt {
//     padding: 14px 25px;

//     .noti-titel {
//       color: #42526e;
//     }
//   }
// }
label {
  font-size: 12px;
  color: #6b778c;
  margin-bottom: 0.5rem;
}

.personal-info {
  border-radius: 12px;
  background-color: $light;

  label,
  p {
    font-size: 12px;
    color: #6b778c;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 17px;
  }
}

.form-control {
  border-radius: 8px;
  height: 48px;
  color: #212529;
  border: 1px solid #ced4da;
  background-color: $light;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08);
  font-family: $montserrat;

  &::placeholder {
    color: #212529;
  }
}

.country_code_div {
  width: 40%;
  margin-right: 15px;
}

.auth-massage_div {
  display: flex;
  align-items: center;
}

.auth-code-btn {
  .btn-rp-primary {
    width: 100%;
  }
}

.term_cndtion {
  min-height: 810px;

  .term-cond-cnt {
    max-height: 500px;
    overflow: auto;
    padding: 10px;
    border: 1px solid $rp-primary-color;
    border-radius: 8px;
    @include scrollBar;

    p {
      color: #000000;
      line-height: 1.4rem;
    }
  }
}

.result-sub-hed {
  color: #c4c4c4;
  font-weight: bold;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.3px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 1rem;
}

.result-titel {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: $rp-primary-color;
  margin-bottom: 0.8rem;
}

.result-dic {
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: $rp-primary-color;
  margin-bottom: 1.5rem;

  span {
    font-weight: bold;
    // color: $rp-primary-color;
  }
}

.trend-card {
  background-color: $light;
  display: flex;
  border-radius: 12px;
  padding: 3rem;

  .btn-rp-primary {
    font-weight: bold;
  }

  .trend-graph-cnt {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    p {
      color: $rp-primary-color;
      font-size: 13px;
    }

    .high {
      text-align: end;
    }
  }

  .asses-mnt {
    color: $rp-primary-color;
    font-size: 12px;
    padding: 0 10px;
  }
}

.analytic-card {
  background-color: $light;
  display: flex;
  flex-wrap: wrap;
  border-radius: 12px;
  padding: 3rem;

  .result-sub-hed,
  .result-titel,
  .result-dic {
    color: $rp-primary-dark;
  }

  span {
    font-size: 24px;
  }

  .more-titel {
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.3px;

    color: $rp-primary-dark;
  }

  .btn-rp-secondary {
    min-width: unset;
    padding: 10px 15px;
  }
}

.result-titel.gradient-text {
  line-height: 40px;
}

.gradient-text {
  background: linear-gradient(
    104.76deg,
    #00696a 2.28%,
    rgba(0, 105, 106, 0.59) 168.34%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  &::selection {
    -webkit-text-fill-color: #fff;
  }
}

.assessment-factor-card {
  background: $light;
  border-radius: 12px;
  padding: 2.5rem;

  .progress {
    height: 2rem;

    .progress-bar {
      margin-right: 2px;
    }

    .progress-bar:last-child {
      border-right: unset;
    }
  }

  .scaling-factor {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: $rp-primary-dark;
    }
  }

  .resource {
    font-size: 11px;
    line-height: 14px;
    color: #818181;
  }
}

.platform-engae,
.burnout_level {
  background-color: $light;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.04);

  .graph {
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02),
      0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 20px;

    .platform-perfomance {
      display: flex;

      .platform-stats {
        width: 20%;
        padding: 17px;

        .hed {
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.3px;
          margin-bottom: 20px;
          color: $rp-dark-gray;
        }

        .stats {
          font-weight: 400;
          font-size: 48px;
          line-height: 30px;
          letter-spacing: 0.3px;
          margin-bottom: 20px;
          color: $rp-primary-color;
        }

        .out-of {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          color: $rp-primary-color;
        }
      }
    }
  }

  .participant-card {
    position: relative;
    padding: 20px;
    min-height: 410px;

    .participation-group {
      font-weight: 700;
      font-size: 12px;
      line-height: 36px;
      letter-spacing: 0.3px;
      color: $rp-primary-dark;
    }

    .out-of {
      color: $rp-dark-gray;
    }

    .titel {
      margin: unset;
      margin-bottom: 20px;
    }

    .titel:nth-child(1) {
      margin-top: 1.25rem;
    }

    .participation-stats {
      font-size: 48px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: $rp-primary-color;

      span {
        font-size: 14px;
      }
    }

    .btn-rp-secondary {
      width: 100%;
    }

    .remaining {
      color: $rp-primary-color;
      font-size: 14px;
      text-align: center;
      margin-top: 5px;
    }

    .reminder {
      position: absolute;
      bottom: 10px;
    }

    .dots-styles {
      width: 100%;
    }

    // &:nth-child(1) .titel,
    // &:nth-child(2) .titel {
    //   margin-top: 1.5rem;
    // }
  }
}

.burnout_level .participant-card {
  min-height: 280px;
  width: 19%;

  .participation-group {
    line-height: 14px;
  }
}

.factor-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .factor-ico.active {
    background-color: #e2f1f1;
  }
}

.factor-ico {
  background: #fafafa;
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 135px;
  position: relative;

  img {
    width: unset;
    margin-bottom: 15px;
  }

  p {
    font-size: 14px;
    color: $rp-primary-color;
  }
}

.factor-ico:hover {
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.01), 0px 4px 2px rgba(0, 0, 0, 0.01);
  background-color: $light;
}

.factor-ico:last-child {
  margin-right: unset;

  img {
    margin-top: 0px;
  }
}

.satisfactory-arrow,
.high-arrwo,
.low-arrow {
  position: absolute;
  right: 10px;
  top: 10px;

  i {
    color: #706562;
  }
}

.high-arrwo i {
  color: $rp-primary-color;
}

.low-arrow i {
  color: $rp-primary-red;
}

///////////////////////////
// flip card
//////////////////////////
.graph-card-height {
  min-height: 600px;
}

.scene--card {
  min-height: 400px;

  .result-card {
    transition: transform 0.4s ease-in-out;
    transform-style: preserve-3d;
    position: relative;

    .card__face {
      position: absolute;
      padding: 25px;
      backface-visibility: hidden;
      width: 100%;

      p,
      h6 {
        position: relative;
      }

      .result-titel {
        color: $light;
        font-size: 34px;
        line-height: 28px;
        margin-bottom: 20px;
      }

      .result-sub-hed {
        font-size: 14px;
        line-height: 36px;
        font-weight: bold;
        margin-bottom: 15px;

        p {
          color: $light;
          letter-spacing: 0.4px;
          font-family: 'Montserrat', sans-serif;
        }
      }

      .result-dic {
        p {
          color: #ffffffab;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .chart-disc {
        p {
          color: $light;
          font-weight: 400;
          font-size: 12px;
        }

        span {
          color: #ffffffab;
          font-weight: 400;
          font-size: 12px;
        }
      }
    }

    .card__face--back {
      transform: rotateY(180deg);

      .result-dic {
        letter-spacing: 2.3px;

        p {
          color: $light;
        }
      }
    }
  }
}

.card-light {
  background-color: $light;

  .result-sub-hed {
    p {
      color: $rp-primary-color !important;
    }
  }

  .result-titel {
    color: $card-heading !important;
  }

  .result-dic,
  .chart-disc {
    p,
    span {
      color: rgba(44, 65, 67, 0.5) !important;
    }
  }
}

.is-flipped {
  transform: rotateY(180deg);
}

.achivment {
  border-radius: 12px;
  padding: 5rem 2rem;
  @include alignCenter;
  flex-direction: column;
  position: relative;
  min-height: 780px;
  background: linear-gradient(
    104.76deg,
    #00696a 2.28%,
    rgba(0, 105, 106, 0.59) 168.34%
  );

  .btn-rp-secondary {
    width: 190px;
  }

  h5,
  h6 {
    font-size: 24px;
    font-weight: 600;
    color: $light;
    letter-spacing: 1.3px;
  }

  h6 {
    font-weight: 400;
  }
}

// top navigation
.menu-items {
  .user-id {
    margin-left: 0.2rem;
  }

  position: absolute;
  background: $light;
  right: -10px;
  top: 40px;
  border-radius: 12px;
  display: none;
  z-index: 1000;
  animation: fadeIn 0.5s;
}

ul.fade-down {
  min-width: 350px;
  padding: 20px;
  padding-bottom: 0;
}

ul.fade-down li {
  cursor: pointer;
}

.dropdown-item {
  font-size: 1rem;
  color: #2c4143;
  padding: 5px 0;

  &:hover,
  i {
    background-color: unset;
    color: $rp-primary-color;
  }

  i {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: #d7d7d7;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:focus,
  &:active {
    background-color: unset;
    color: $rp-primary-color;
  }
}

.nav-bb {
  border-bottom: 1px solid #b1b1b180;
}

.modal-body {
  .section-heading {
    font-size: 25px;
    letter-spacing: 0.3px;
    line-height: 40px;
  }

  .profile-edit .profile-icon {
    height: 50px;
    width: 50px;
    font-size: 20px;
  }

  input[type='radio']:checked + label {
    background-color: transparent;
  }
}

.modal-footer {
  border-top: unset;
}

.modal-header {
  border-bottom: unset;
  // padding: 1rem 2rem;
  background-color: #eee;
  margin-bottom: 10px;
  border-bottom: inset;

  .modal-title {
    color: $rp-dark-gray;
    font-weight: 600;
    font-size: 17px;
  }
}

.noti-menu {
  display: none;
}

.primary-footer {
  background: $light;
  padding: 20px 0;

  .ft-logo img {
    width: 130px;
  }

  a,
  span {
    color: #0a6e6f;
    transition: all ease-in-out;
    font-weight: 700;
    letter-spacing: 0.3px;
    font-size: 13px;
    cursor: pointer;
  }
}

.icon {
  margin-right: 2rem;

  i {
    color: #00a89d;
    font-size: 35px;
    line-height: 0;
  }
}

.li-cnt {
  h6 {
    margin-bottom: 0;
    line-height: 1;
  }
}

// rp plan
.rp-plan-card {
  .card-heading-light,
  .sub-titel,
  p {
    color: $rp-dark-gray;
  }

  .card-heading-light {
    font-size: 36px;
    line-height: 36px;
  }

  span {
    color: $rp-dark-gray;
    font-weight: 600;
    font-size: 59px;
    letter-spacing: 0.3px;
  }
}

// .plan-group {
//   padding: 0;
//   background-color: #edefee;

//   .group-items {
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//     margin-bottom: 0;
//     padding: 0;
//     height: 45px;

//     .group-item {
//       cursor: pointer;
//       color: $rp-primary-color;
//       // font-family: $roboto;
//       text-align: center;
//     }
//   }

//   .show-more i {
//     color: $rp-primary-color;
//   }
// }

.slick-arrow i {
  color: $rp-primary-color;
}

// .item-uplode {
//   width: 90px;
//   height: 90px;
//   margin: auto;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #f2f2f2;
//   text-align: center;
//   cursor: pointer;

//   i {
//     font-size: 2.5rem;
//   }
// }

// .item-uplode-title {
//   color: #7a869a;
// }

// .add-people {
//   span {
//     color: #7a869a;
//   }
// }

.people-page {
  .shorting_icon {
    position: relative;
  }

  .shorting_icon::before,
  .shorting_icon::after {
    right: 5px;
    content: '\f0de';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-size: 1rem;
    color: #fff;
    position: absolute;
    opacity: 0.3;

    .active {
      color: #000;
    }
  }

  .shorting_icon.shorting_up::before,
  .shorting_icon.shorting_down::after {
    opacity: 1;
  }

  .shorting_icon::after {
    content: '\f0dd';
  }
}

.user_all_option {
  visibility: hidden;
}

.profile-edit {
  .profile-icon {
    height: 89px;
    width: 89px;
    font-size: 35px;
    background-color: #95b4b8;
  }

  .edit-profile-actions {
    .btn-rp-primary {
      margin-right: 15px;
    }
  }
}

.view-profile {
  .profile-icon {
    height: 158px;
    width: 158px;
    font-size: 60px;
    background-color: #95b4b8;
  }

  .profile-tabs {
    .profile-tab {
      min-height: 85px;
      border-radius: 12px;
      box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.04);

      .tab-titel {
        color: $rp-dark-gray;
        line-height: 1;
      }

      .tab-sub-titel {
        font-size: 14px;
        color: $rp-primary-color;
        font-family: $montserrat;
        font-weight: bold;
      }

      i {
        color: #d3d3d3;
      }
    }
  }
}

.designation {
  color: $rp-primary-color;
  font-weight: 700;
  font-size: 16px;
}

.analytics-card {
  .analytics-titel {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.3px;
    color: $rp-primary-color;
  }

  .sub-para {
    font-weight: bold;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.3px;
    color: $rp-primary-dark;
  }

  .card-sub-head {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: $rp-primary-color;
  }
}

// .overall-res-bar {
//   .progress {
//     height: unset;
//     border-radius: 12px;
//     overflow: unset;

//     .progress-bar {
//       height: 2rem;
//       margin-right: 2px;
//       cursor: pointer;
//       transition: all ease 0.3s;
//       overflow: unset;

//       // &:last-child {
//       //   margin-right: 0;
//       //   border-radius: 0 12px 12px 0;
//       // }
//       // &:first-child {
//       //   border-radius: 12px 0 0 12px;
//       // }
//     }
//     .progress-bar-active {
//       transform: scaleY(1.3);
//       margin: 0px 2px;
//     }
//   }
//   .res-tab {
//     box-shadow: none;
//   }
// }
.company-factor-green,
.company-factor-red {
  display: flex;
  align-items: flex-start;
}

.company-factor-green {
  color: $rp-primary-dark;
}

.company-factor-red {
  color: $rp-primary-red;
}

.company-factor-green::before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z' fill='%23006061'%3E%3C/path%3E%3C/svg%3E");
  margin-right: 5px;
}

.company-factor-red::before {
  content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM4 12.172C4.04732 16.5732 7.64111 20.1095 12.0425 20.086C16.444 20.0622 19.9995 16.4875 19.9995 12.086C19.9995 7.68451 16.444 4.10977 12.0425 4.086C7.64111 4.06246 4.04732 7.59876 4 12V12.172ZM14 17H11V13H10V11H13V15H14V17ZM13 9H11V7H13V9Z' fill='%23E02726'%3E%3C/path%3E%3C/svg%3E");
  margin-right: 5px;
}

// .res-tab {
//   box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.1);
//   width: 20%;
//   background-color: $light;
//   padding: 20px;
//   cursor: pointer;
//   margin-right: 1.4rem;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   text-align: center;
//   font-family: $montserrat;
//   flex-grow: 1;

//   .titel {
//     color: #7a869a;
//     margin-bottom: 10px;
//     font-size: 17px;
//     line-height: 22px;
//   }

//   &:hover {
//     .titel {
//       color: $rp-primary-color;
//     }
//   }
//   .critical {
//     color: #e02726 !important;
//   }
// }

// .res-tab:last-child {
//   margin-right: 0;
// }

// multidropdown

.overall-percent {
  font-size: 30px;
  line-height: 32px;
  align-items: center;
  letter-spacing: 0.3px;
  color: $rp-primary-color;
  font-family: $montserrat;
}

// .fillter-nav-bar {
//   background-color: #fff;
//   padding: 0 1rem;
//   border-radius: 12px;
//   border-top-left-radius: 0;
//   align-items: center;
//   justify-content: space-between;
// }

.badge {
  border: 1px solid #ebebeb;
  color: $rp-primary-color;
  font-family: $montserrat;
  font-size: 12px;
  font-weight: normal;
  background-color: $light;
}

.filter-down {
  position: relative;
  // min-width: 178px;
  max-width: 280px;
}

.analytics-filter {
  min-height: 75px;
  border-radius: 12px;
  padding: 1rem 0;
  margin-right: 1rem;
  align-items: center;

  .dropdown-menu {
    min-width: 170px;
    padding: 10px;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

    .dropdown-item {
      font-size: 14px;
      color: $rp-dark-gray;

      .form-check-label {
        margin-bottom: 0;
        margin-top: 2px;
      }
    }
  }

  .filter-titel,
  i {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $rp-dark-gray;
  }

  .filter-titel {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .filter-pills {
    .badge {
      cursor: pointer;
      border: $rp-dark-gray 1px solid;
      border-radius: 29px;
      color: $rp-dark-gray;
      padding: 10px;
    }
  }

  i {
    margin-left: 8px;
  }

  .filter-option {
    font-size: 12px;
    line-height: 14px;

    color: #818181;
  }

  .filter-option.active {
    color: $rp-primary-color;
    background-color: unset;
  }

  .active-dot {
    margin-left: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $rp-primary-color;
  }
}

.analytics-filter.filter-active {
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.06);
}

.drop-opt {
  position: absolute;
  background-color: $light;
  border-radius: 12px;
  padding: 1rem;
  left: 0;
  width: 300px;
  display: none;
  z-index: 2;

  input[type='radio']:checked + label {
    background-color: unset;
  }
}

#lodeMore {
  display: none;
}

.prodduct-thumbnail {
  margin-left: 15px;
  padding: 15px;
  background-color: $light;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 400px;
  overflow: hidden;

  img {
    border: 1px solid $rp-primary-color;
    cursor: pointer;
  }
}

.recipe-titel {
  margin-bottom: 1rem;

  h5 {
    color: $rp-dark-gray;
  }
}

.instructions {
  li {
    display: flex;

    &::before {
      content: '\F2CF';
      font-family: 'bootstrap-icons';
      margin-right: 5px;
      color: $rp-primary-color;
    }
  }
}

.ingredients {
  li {
    list-style: inherit;
  }
}

.nutritions {
  .nutrients {
    &::before {
      content: '\f299';
      font-family: 'Font Awesome 6 Brands';
      margin-right: 5px;
      color: $rp-primary-color;
    }
  }

  .nutrition-label-container {
    .col-md-6 {
      padding-left: 2rem;
    }
  }
}

// .blockquote {
//   font-size: 2rem;
//   font-weight: 600;
//   color: $light;
// }
// .blockquote-footer {
//   color: $light;
// }

// .music-player {
//   margin-top: 1rem;
//   height: 100px;
//   position: sticky;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   color: $light;
// }
// .player-controls {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   .control-icon {
//     display: flex;
//     justify-content: space-evenly;
//     i {
//       color: $light;
//       font-size: 1.5rem;
//       cursor: pointer;
//     }
//   }
//   input {
//     width: 40vw;
//   }
// }
// .song-thumbnail {
//   width: 70px;
// }
// .song-titel,
// .artist {
//   color: $light;
// }
// // music slider range bar
// .slider-container {
//   position: relative;
//   .bar {
//     position: absolute;
//     z-index: 1;
//     top: 12px;
//     width: 100%;
//     height: 5px;
//     border-radius: 5px;
//     background-color: $light;
//     overflow: hidden;

//     .fill {
//       display: block;
//       width: 0;
//       height: 100%;
//       background-color: $rp-primary-color;
//     }
//   }
//   .slider {
//     position: relative;
//     z-index: 2;
//     cursor: pointer;
//     height: 5px;
//     border-radius: 5px;
//     outline: none;
//     background-color: transparent;

//     &::-webkit-slider-thumb {
//       width: 0.9rem;
//       height: 0.9rem;
//       background-color: $rp-primary-color;
//       border-radius: 50%;
//       transition: 0.3s ease-in-out;
//     }

//     &::-webkit-slider-thumb:hover {
//       box-shadow: 0 0 0 10px rgba(0, 163, 157, 0.2);
//     }
//     &::-webkit-slider-thumb:active {
//       box-shadow: 0 0 0 15px rgba(0, 163, 157, 0.2);
//     }
//   }
// }

.manage_group {
  .accordion-button {
    border-radius: 43px !important;
  }

  .accordion-item {
    border-radius: 43px 43px 0 0 !important;
    margin-bottom: 20px;
    border-bottom: none;
    background-color: unset;
  }

  .accordion-collapse.collapse {
    border-radius: 0 !important;
  }

  .accordion-button.collapsed {
    border-radius: 43px !important;
  }

  .accordion-collapse.collapse.show {
    background: #fff;
  }

  .accordion-collapse.collapse {
    border-radius: 12px !important;
  }

  .accordion-body {
    margin-top: 10px;
  }

  .accordion-button:focus {
    box-shadow: none;
    border-color: unset;
  }

  .accordion-button:not(.collapsed) {
    color: unset;
    background-color: #fff;
    box-shadow: none;
  }

  .accordion-button {
    .col-2 {
      width: 15.666667%;
    }
  }

  .accordion-button::after {
    background-image: unset;
    content: '\f107';
    font-family: 'FontAwesome';
    width: unset;
    height: unset;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: unset;
    content: '\f106';
    font-family: 'FontAwesome';
    color: $rp-primary-color;
  }
}

#navbar_top {
  display: none;

  .accordion-button {
    border-radius: 12px !important;
  }

  .accordion-button.collapsed {
    border-radius: 12px !important;
    padding-bottom: 15px;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02),
      0px 4px 4px rgba(0, 0, 0, 0.04);
  }

  .accordion-button {
    width: unset;
    padding: 15px;
    padding-bottom: 0;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .accordion-body {
    padding: unset;
    margin: unset;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02),
      0px 4px 4px rgba(0, 0, 0, 0.04);
  }
}

.list-group-item:nth-child(odd) {
  background-color: #f6f6f6;
}

.page-item {
  margin-right: 10px;
}

.page-link {
  background: transparent;
  border: none;
  color: #42526e;

  span {
    i {
      font-size: 15px;
      color: $rp-primary-color;
    }
  }
}

.page-link:focus,
.page-link:hover {
  color: $rp-primary-color;
  box-shadow: none;
  border-radius: 3px;
}

.page-link.active {
  background-color: $rp-primary-color;
  color: $light;
  border-radius: 3px;
  border: none;
}

.filter-drop {
  background-color: $light;
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  min-height: 200px;
  width: 350px;
  position: absolute;
  margin-top: 10px;
  z-index: 500;
}

// .country-location {
//   height: 350px;
//   width: 100%;
//   margin-top: 15px;
//   overflow: auto;
//   padding: 5px;

//   &::-webkit-scrollbar {
//     width: 4px;
//   }
//   &::-webkit-scrollbar-track {
//     background: #f8f8f8;
//     border-radius: 10px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #a1a1a1;
//     border-radius: 10px;
//   }
//   &::-webkit-scrollbar-thumb:hover {
//     background: #a8a8a8;
//   }
//   ul {
//     margin-bottom: 0;
//   }
//   .country-li {
//     background: #ffffff;
//     box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.04);
//     padding: 0 10px;
//     border-radius: 12px;
//     font-family: $montserrat;
//     font-weight: 500;
//     font-size: 15px;
//     line-height: 14px;
//     color: #42526e;
//     min-height: 40px;
//     margin-bottom: 15px;
//     padding: 12px 10px;

//     ul {
//       position: relative;
//     }
//     ul::before {
//       position: absolute;
//       content: '';
//       left: 6%;
//       color: #000;
//       bottom: 0;
//       height: 98%;
//       width: 2px;
//       background: $rp-primary-color;
//       z-index: 1;
//     }

//     li {
//       font-family: $montserrat;
//       font-weight: 500;
//       font-size: 14px;
//       line-height: 28px;
//       color: #42526e;
//     }
//   }
// }

// .grp-mang-hed {
//   font-family: $montserrat;
//   display: flex;
//   align-items: center;
//   background-color: $light;
//   border-radius: 12px 50px 50px 12px;
//   padding: 0;

//   .mang-hed-items {
//     padding: 1rem 1.25rem;
//     display: flex;
//     .hed-item {
//       color: $rp-primary-color;
//       font-size: 14px;
//       font-family: $montserrat;
//     }
//     .group-name {
//       font-weight: 600;
//       font-size: 16px;
//     }
//   }
// }

.accordion-button {
  .accordion-hed-item {
    color: $rp-dark-gray;
    font-size: 14px;
    font-family: $montserrat;
  }

  .group-name {
    font-weight: 600;
    font-size: 16px;
  }
}

.accordion-body {
  // padding: 2.5rem;
  .titel {
    text-decoration: underline;
    color: $rp-dark-gray;
    font-family: $montserrat;
  }

  .sub-titel {
    margin-top: 20px;
    font-family: $montserrat;
    font-size: 20px;
    line-height: 20px;
    color: $rp-dark-gray;
    font-weight: 400;
  }

  .grp-items {
    display: flex;
    justify-content: space-between;

    .item-li-hed,
    .item-li {
      color: $rp-primary-color;
      font-size: 14px;
      font-family: $montserrat;
    }

    .item-li {
      color: $rp-dark-gray;
    }

    .item-tabs {
      color: $rp-primary-color;
      font-family: $montserrat;
    }
  }
}

.participant-card {
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02), 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 10px;
  min-height: 310px;

  .titel {
    font-family: $montserrat;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    margin: 23px 0;
    color: #818181;
  }

  p {
    font-size: 14px;
  }

  .impt {
    color: #818181;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
  }
}

.user-result {
  ul li {
    display: flex;
  }
}

.user-results {
  .resillency-factor {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 14px;
      letter-spacing: 0.3px;
      color: #006061;
    }
  }

  .factor-bar {
    position: relative;
    height: 20px;

    .place {
      background: $rp-primary-dark;
      width: 2px;
      bottom: 4px;
      height: 100%;
      position: absolute;
      cursor: pointer;

      span {
        position: absolute;
        top: 20px;
        left: -10px;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .scaling-factor {
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
    }
  }
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.fa-chevron-down {
  transition: all 0.3s ease;
}

// .resillency-factor-card {
//   ul li {
//     display: flex;

//     .li-cnt {
//       font-weight: 500;
//       p {
//         margin-top: 10px;
//       }
//     }
//   }
//   min-height: 593px;
//   .product-card-main,
//   .result-card {
//     min-height: 593px;
//   }
//   .resillency-factor {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 10px;
//     span {
//       font-weight: 500;
//       font-size: 15px;
//       line-height: 14px;
//       letter-spacing: 0.3px;
//       color: #006061;
//     }
//   }
//   .scaling-factor {
//     margin-top: 10px;
//     display: flex;
//     justify-content: space-between;
//     span {
//       font-weight: 500;
//       font-size: 12px;
//       line-height: 14px;
//       letter-spacing: 0.3px;
//     }
//   }
// }
.result-summry {
  p,
  li {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .about-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 26px;
    color: $rp-primary-color;
  }
}

.more-about-result {
  li {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.result-summry-text {
  ul {
    position: relative;
    padding-left: 2.5rem;

    li {
      color: $rp-dark-gray;
      font-size: 15px;
    }

    li::before {
      content: '\e4be';
      font-family: 'FontAwesome';
      position: absolute;
      left: 15px;
      color: $rp-primary-color;
    }
  }

  .icon {
    margin-right: 1rem;

    i {
      font-size: 25px;
    }

    .bi-command::before {
      margin-top: 7px;
    }
  }
}

.lode-more {
  margin-top: 1rem;
}

.tooltip-inner {
  max-width: 106px !important;
  background-color: $rp-primary-color;
  font-size: 12px;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: $rp-primary-color;
}

.bs-tooltip-end {
  .tooltip-arrow::before {
    border-right-color: $rp-primary-color;
  }

  .tooltip-inner {
    max-width: 220px !important;
  }
}

.custom-tooltip {
  i {
    font-size: 15px;
    color: $rp-primary-color;
    cursor: pointer;
  }
}

.factor-content {
  display: none;
}

.black-color {
  color: #000 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.progress-bar.primary-color.no-fill-color {
  background-color: #e9ecef !important;
}

.factor-progress-icon {
  position: absolute;
  top: 7px;
  right: 7px;
}

// terms and services

.table > :not(:first-child) {
  border-top: unset;
}

.table thead th {
  background-color: $rp-primary-color;
  color: #fff;
}

.table thead tr th {
  font-weight: 400;
  line-height: 20px;
}

.table tbody tr {
  font-size: 15px;
}

.table tbody tr td {
  line-height: 20px;
}

.list-dot li {
  list-style: disc;
}

.list-group-numbered > li::before {
  font-weight: bold;
}

.modal-heding {
  text-align: center;
  text-decoration: underline;
  font-size: 1.4rem;
  color: $rp-primary-color;
  font-weight: 400;
}

.result_dot_style {
  height: 36%;
  width: 30%;
}

.auth-btn {
  margin-top: 35px;
  margin-left: 15px;
}

.confirmation-footer {
  .btn-rp-primary-red,
  .btn-rp-primary {
    min-width: unset;
  }
}

// new analytics page
.question-anlytics_graph {
  width: 100%;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  background-color: $light;

  &:last-child {
    margin-bottom: unset;
  }

  .title {
    text-align: center;
    margin-bottom: 1.5rem;
    position: relative;

    span {
      color: $dark;
      font-weight: 600;
    }

    .swap,
    .extend-btn,
    .download {
      cursor: pointer;

      &::before {
        content: '\f079';
        font-size: 1rem;
        font-family: 'fontawesome';
        position: absolute;
        right: 10px;
        border: 1px solid $dark;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 5px 10px;
        border-radius: 8px;
      }
    }

    .extend-btn {
      &::before {
        font-family: bootstrap-icons;
        content: '\F14D';
        right: 70px;
      }
    }

    .download {
      &::before {
        content: '\f019';
        // right: 10px;
      }
    }
  }
}

// .question-option option {
//   height: 100px;
//   overflow-y: auto;
// }
// .send-reminder {
//   background-image: url("../images/reminder.png");
//   height: 30px !important;
//   background-position: center;
//   background-repeat: no-repeat;
//   width: 30px;
//   background-size: 18px;

//   &:hover {
//     background-image: url("../images/reminder-white.png");
//   }
// }
// .overall-res-bar {
//   justify-content: space-between;
//   margin-bottom: 1.5rem;
//   .btn-rp-secondary {
//     min-width: 150px;
//     border-radius: 6px 6px 0 0;
//     border-bottom: unset;

//     &:hover {
//       background-color: $light;
//       color: $rp-primary-color;
//     }
//   }
//   .active {
//     border-radius: 6px;
//     .btn-rp-secondary {
//       background-color: $rp-primary-color;
//       color: $light;
//     }
//   }
// }

// .overview_tab {
//   transition: all ease 0.3s;

//   &:hover .btn-rp-secondary {
//     background-color: $rp-primary-color;
//     color: $light;
//   }
//   &:hover .progress-label {
//     border-top: 1px solid $light;
//   }
// }
// .progress-label {
//   background-color: $rp-primary-color;
//   border: 1px solid $rp-primary-color;
//   border-radius: 0 0 6px 6px;
//   display: flex;
//   cursor: pointer;
//   .label {
//     font-size: 12px;
//     color: #818181;
//   }
//   .label-cent {
//     // color: $rp-primary-color;
//     font-size: 20px;
//     color: $light;
//     font-size: 20px;
//     line-height: 23px;
//     // background: $rp-primary-color;
//     border-bottom-left-radius: 3px;
//     width: 50%;
//     text-align: center;
//   }
//   .progress-label {
//     padding-left: 2px;
//   }
//   .participants-count {
//     color: $light;
//     width: 100%;
//     text-align: center;
//   }

//   &:hover .btn-rp-secondary {
//     background-color: $rp-primary-color;
//     color: $light;
//   }
// }
// .scale-up {
//   transform: scale(1.1);
//   box-shadow: rgba($color: #000, $alpha: 0.25) 0px 3px 15px;
//   border-radius: 6px;

//   .progress-label {
//     border-top: 1px solid #fff;
//   }
// }
.over-tab-div {
  justify-content: space-between;
  margin-bottom: 1.5rem;

  .over-tab {
    .btn-rp-secondary {
      min-width: 150px;
    }

    .active {
      background-color: $rp-primary-color;
      color: $light;
      transform: scale(1.1);
      box-shadow: rgba($color: #000, $alpha: 0.25) 0px 3px 15px;
    }

    .progress-label {
      margin-top: 10px;

      .label {
        font-size: 12px;
        color: #818181;
      }

      .label-cent {
        // color: $rp-primary-color;
        font-size: 20px;
        color: $rp-primary-color;
        font-size: 20px;
        line-height: 23px;
        // background: $rp-primary-color;
        border-bottom-left-radius: 3px;
        width: 50%;
        text-align: center;
      }

      .progress-label {
        padding-left: 2px;
      }

      .participants-count {
        color: $rp-dark-gray;
        width: 100%;
        text-align: center;
      }
    }
  }
}

// .overall-res-bar {
//   .progress {
//     // border-radius: 6px;
//     margin-right: 5px;
//     cursor: pointer;
//     width: 20%;
//     transition: all ease 0.3s;
//     &:hover {
//       transform: scaleY(1.3);
//       margin-right: 5px;
//       // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02),
//       //   0px 4px 4px rgba(0, 0, 0, 0.04);
//       box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.17);
//     }

//     .progress-bar {
//       border-radius: 12px;
//       height: 1.5rem;
//     }
//   }
//   .progress-bar-active {
//     transform: scaleY(1.3);
//     margin-right: 5px;
//     box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.17);
//     // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.02),
//     //   0px 4px 4px rgba(0, 0, 0, 0.04);
//   }
//   // .progress:nth-child(1) {
//   //   background-color: rgba(0, 163, 157, 0.15);
//   // }
//   // .progress:nth-child(2) {
//   //   background-color: rgba(56, 132, 127, 0.15);
//   // }
//   // .progress:nth-child(3) {
//   //   background-color: rgba(112, 101, 98, 0.15);
//   // }
//   // .progress:nth-child(4) {
//   //   background-color: rgba(168, 70, 68, 0.15);
//   // }
//   // .progress:nth-child(5) {
//   //   background-color: rgba(224, 39, 38, 0.15);
//   // }
// }
.dashboard_filter {
  .accordion-header {
    border-radius: 6px;
  }

  .accordion-button {
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: $rp-dark-gray;

    &::after {
      background-image: unset;
      content: none;
    }

    &::before {
      margin-right: 20px;
      // transition: transform 0.2s ease-in-out;
    }
  }

  .accordion-button::before {
    background-image: unset;
    content: '\f055';
    font-family: 'FontAwesome';
    width: unset;
    height: unset;
    transform: rotate(-180deg);
  }

  .accordion-button:not(.collapsed)::before {
    background-image: unset;
    content: '\f056';
    font-family: 'FontAwesome';
    color: $rp-primary-color;
  }

  .accordion-button:not(.collapsed) {
    background-color: unset;
  }

  .accordion-body {
    padding: 10px;
  }
}

// 2FA

.tab-pane.active {
  background-color: unset;
}

.nav-tabs {
  .nav-link {
    color: $rp-primary-color;
  }
}

// //////////
.thanq_page,
.appoitment-massage {
  color: $rp-primary-dark;
  font-size: 18px;
  font-weight: 600;
}

// appointment-massage
.appoitment-massage {
  margin-bottom: 1.5rem;
}

// new daily link page
.plan_container {
  display: flex;
  margin: 20px 0;
}

.side_nav.open {
  width: 20%;
}

.side_nav {
  background-color: $light;
  border-radius: 8px;

  .sidebar {
    // min-height: 100vh;
    height: calc(100vh - 161.6px);
    overflow-y: auto;
    position: sticky;
    top: 136px;
    padding: 0 15px;
    @include scrollBar;

    .sidebar_list {
      .side-nav {
        color: $rp-primary-color;
        transition: all ease 0.2s;
        border-radius: 3px;
        display: flex;
        align-items: center;
        transition: all ease 0.3s;
        padding: 0 0.5rem;
        margin-bottom: 0.9rem;
        min-height: 40px;

        .li_link {
          display: flex;
          color: $rp-primary-color;
          font-size: 16px;
          cursor: pointer;
        }

        .submenu {
          margin-left: 50px;
          padding: 0;

          .nav-link {
            color: #42526e;
            cursor: pointer;
            opacity: 0.7;
            font-weight: 500;
            font-size: 1rem;

            &:hover {
              opacity: 1;
            }
          }

          .active_sub {
            font-weight: 600;
            opacity: 1;
          }
        }

        span {
          @include alignCenter;
        }

        // i {
        //   color: $rp-primary-color;
        // }

        // .li_icon {
        //   height: 48px;
        //   width: 48px;
        //   border: 2px solid $rp-primary-color;
        //   border-radius: 50%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }
        .li_title {
          color: $rp-dark-gray;
          font-weight: 500;
          animation-delay: 0.3s;
        }

        .li_icon {
          margin-right: 20px;

          i {
            font-size: 1.6rem;
            transition: all ease 0.3s;
            color: $rp-dark-gray;
          }
        }

        // &:hover {
        //   color: $rp-primary-color;
        // }
        &:hover .li_icon i,
        &:hover .li_title {
          color: $rp-primary-color;
        }
      }

      .active_li {
        .li_icon {
          i {
            color: $rp-primary-color;
          }
        }

        .li_title {
          font-weight: 700;
          color: $rp-primary-color;
        }
      }

      .favourite_li {
        .li_icon {
          i {
            color: $rp-dark-gray;
          }
        }
      }

      .favourite_li.active_li {
        .li_icon {
          i {
            color: #ff5959;
          }
        }
      }

      .favourite_li:hover .li_link .li_icon {
        // background-color: #ff5959;
        i {
          color: #ff5959;
        }
      }

      .filter-sep {
        margin: 0;
        width: 100%;
        border: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);
      }

      .filter-sec {
        &:hover .li_icon i,
        &:hover .li_title {
          color: $rp-dark-gray;
        }

        .side-nav {
          margin: 5px 0;
        }

        .li_icon i {
          font-size: 1.5rem;
        }
      }
    }
  }
}

// .side-nav i {
//   font-size: 1.5rem;
//   transition: all ease 0.3s;
//   color: $rp-dark-gray;
// }

// .li_link:hover .li_icon {
//   background-color: $rp-primary-color;
// }

// .li_link:hover.li_link i {
//   color: $rp-primary-color;
// }

.plan-groups {
  @include spaceBetween;
}

.side-nav:hover .drop_toggler i {
  color: $rp-primary-color;
}

// .sidebar_list {
//   .side-nav {
//     .li_title:hover.li_title {
//       color: $rp-primary-color;
//     }
//   }
// }

.drop_toggler {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all ease 0.3s;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $rp-primary-color, $alpha: 0.2);
  }
}

.drop_toggler:not(.collapsed) i {
  transform: rotate(-180deg);
}

.main_content {
  width: 80%;
  padding: 0 20px;
}

// .side_nav {
//   width: 6%;
//   transition: all ease 0.3s;
//   .li_title {
//     opacity: 0;
//     margin-left: 0;
//   }
//   .drop_toggler {
//     display: none;
//   }
// }
// .side_nav.open {
//   width: 25%;
//   .li_title {
//     opacity: 1;
//     margin-left: 20px;
//   }
//   .drop_toggler {
//     display: flex;
//   }
// }
.card-4 {
  max-width: 400px;
}

.card-5 {
  max-width: 450px;
}

.recipe-section {
  // animation: fadeInRight;
  // animation-duration: 0.5s;
  .recipe-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &:nth-child(even) .recipe-col {
    flex-direction: row-reverse;
  }
}

.recipe-ing-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: $rp-dark-gray !important;
  text-decoration: underline;
}

.breadcrumb {
  .breadcrumb-item::before {
    content: '\f105';
    font-family: 'FontAwesome';
  }

  .breadcrumb-item:nth-child(1)::before {
    content: unset;
  }

  .breadcrumb-item {
    .item-link {
      font-size: 15px;
      color: $rp-primary-color;
    }

    color: #333;
  }
}

.plan-table {
  thead tr th {
    border-bottom: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);

    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
    }
  }

  border-radius: 12px;
  background-color: $light;

  tbody tr {
    &:last-child th:first-child {
      border-bottom-left-radius: 12px;
    }

    &:last-child td:last-child {
      border-bottom-right-radius: 12px;
    }
  }

  &.table thead th {
    background-color: $light;
    color: $rp-dark-gray;
    font-weight: 500;
    height: 40px;
  }

  &.table tr td,
  th {
    line-height: 20px;
    height: 40px;
    vertical-align: middle;
    background-color: $light;
  }

  & .table tr td span {
    cursor: pointer;
    color: $rp-primary-color;

    &:hover {
      text-decoration: underline;
    }
  }

  tbody tr:nth-child(odd) td,
  tbody tr:nth-child(odd) th {
    background-color: #f5feff;
  }
}

// redeem page
.user-pts {
  font-size: 15px;
  display: flex;
  align-items: center;

  img {
    width: 40px;
  }
}

.redeem-tabs,
.myreward-tabs {
  .nav-item {
    .active {
      background-color: $rp-primary-color;
      color: $light;
    }
  }
}

// .scan-download {
//   border: 1px solid #00a89d;
//   padding: 7px;
// }
.app-store {
  img {
    width: 55px;
  }
}

.qr-scaner {
  text-align: center;

  img {
    width: 180px;
  }
}

.total-pts {
  display: flex;
  align-items: center;
  border: 1px solid $rp-primary-color;
  border-radius: 8px;
  background-color: $rp-primary-color;
  width: 45%;
  margin: auto;

  .pts-icon {
    width: 100px;
    padding: 10px;
    background-color: $light;
    border-radius: 8px 0 0 8px;
  }

  .pts-text {
    color: $light;
    font-weight: 600;
    text-align: center;
    margin-left: 15px;
    font-size: 17px;
  }
}

.reward-task {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1.5rem 1rem;
  border-radius: 6px;

  .task-col {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .task-col-dec {
      color: $rp-dark-gray;
      width: 50%;
    }

    .task-actions {
      width: 50%;
      @include spaceAround;
    }

    .task-icon-pts {
      display: flex;
      align-items: center;
      font-weight: 700;
      width: 20%;
      color: $rp-primary-color;

      img {
        width: 35px;
        margin-right: 15px;
      }
    }

    .redeem-inst {
      cursor: pointer;
    }

    .action-btn {
      width: 50%;
      text-align: center;

      img {
        width: 45px;
      }
    }
  }
}

.redeem-tab-title {
  text-align: center;
  color: $rp-primary-dark;
  font-size: 30px;
  margin-bottom: 2.5rem;
}

.redeem-table {
  tr {
    height: 50px;
  }
}

// invite relatives
.invite-table,
.people-page {
  border-spacing: 0 10px;
  border-collapse: separate;
  font-size: 15px;

  thead {
    tr {
      th {
        border-right: 2px solid #fff;
      }
    }
  }

  tbody {
    tr {
      border-radius: 12px;
      background-color: #fff;

      td {
        border-bottom: none;

        &:first-child {
          border-radius: 12px 0 0 12px;
          // font-weight: 600;
        }

        &:last-child {
          border-radius: 0 12px 12px 0;
        }
      }
    }
  }
}

.people-page {
  tbody {
    tr {
      td {
        &:first-child {
          font-weight: 600;
        }
      }
    }
  }
}

// login
.from-separator {
  margin-top: 40px;
  position: relative;

  &::before {
    content: 'Login with OTP';
    position: absolute;
    color: #6b778c;
    font-size: 13px;
    background-color: #f7f7f7;
    z-index: 1;
    left: 160px;
    top: -10px;
  }
}

.login-type {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 13px;
  color: #6b778c;
  margin: 20px 0;
}

.login-type::before,
.login-type::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #6b778c;
}

.login-type:not(:empty)::before {
  margin-right: 0.25rem;
}

.login-type:not(:empty)::after {
  margin-left: 0.25rem;
}

.userInput {
  .input-otp {
    width: 75%;
    display: flex;
    justify-content: space-between;
  }

  input {
    height: 35px;
    width: 35px;
    border: 0;
    border-bottom: 1px solid $rp-primary-color;
    // border-radius: 3px;
    text-align: center;
    color: $rp-primary-color;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}

// plan page
.undo-massage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000, $alpha: 0.8);
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  p {
    color: $light;
  }

  span {
    color: $light;
    font-weight: bold;
    cursor: pointer;
  }
}

// playlist
.search-field {
  position: relative;

  i {
    position: absolute;
    left: 15px;
    bottom: 10px;
  }
}

.playlist-massage {
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
}

.empty-play {
  margin-top: 25px;
  border-radius: 12px;
  min-height: 400px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 6rem;
    color: $rp-primary-color;
  }
}

.series-hed {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .card-heading-sec-dark {
    margin-bottom: 0;
  }

  .video-count {
    margin-left: 3rem;
    font-weight: 600;
    color: $rp-primary-color;
  }
}

.playlist-action {
  margin-left: 10px;

  .action-icon {
    margin-left: 5px;
    cursor: pointer;

    i {
      color: $rp-dark-gray;
    }
  }
}

.cnt-source {
  .video-card-menu {
    margin-left: 16px;
    cursor: pointer;
  }
}

// .video-menu {
//   position: absolute;
//   box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
//     rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
//   border-radius: 6px;
//   padding: 10px;
//   background-color: $light;
//   bottom: 45px;
//   right: 38px;
//   display: none;
//   transition: all ease 0.3s;

//   ul {
//     padding: 0;
//     margin: 0;

//     li {
//       font-size: 13px;
//       margin-bottom: 10px;
//       opacity: 0.7;
//       cursor: pointer;

//       &:last-child {
//         margin-bottom: 0;
//       }

//       &:hover {
//         opacity: 1;
//       }
//     }
//   }
// }

.participation-filter-tab {
  display: flex;
  justify-content: space-between;

  .btn-rp-secondary {
    min-width: 150px;
  }

  .btn-active {
    background-color: $rp-primary-color;
    color: $light;
  }
}

.dropdown-check-list {
  width: 100%;
  margin-bottom: 20px;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  @include spaceBetween;
  padding: 10px 15px 10px 10px;
  border-radius: 8px;
  background-color: #f8f8f8;
  width: 100%;
}

.dropdown-check-list ul.items {
  padding: 10px;
  display: none;
  margin-top: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}

// manage participation
.manage-participation-table {
  .table {
    & > :not(caption) > * > * {
      padding: 0.5rem 1rem;
    }

    thead {
      height: 60px;
      border-bottom: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);

      tr {
        th {
          background-color: $light;

          span {
            color: $rp-dark-gray;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            i {
              font-size: 15px;
              color: $rp-dark-gray;
            }

            &:first-child {
              margin-right: 5px;
            }
          }

          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
    }

    tbody {
      tr {
        height: 90px;

        &:nth-child(even) {
          background-color: #f5feff;
        }

        &:nth-child(odd) {
          background-color: $light;
        }

        &:last-child td {
          &:first-child {
            border-bottom-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }

        td {
          border-bottom: none;
          font-size: 14px;
          font-weight: 500;
          color: #40464c;

          &:first-child * {
            &:last-child {
              margin-top: 10px;
            }

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      .action_buttons {
        span {
          margin: 0 15px 0 0;

          i {
            cursor: pointer;
            color: $rp-dark-gray;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.manage-participation-filter {
  .filter-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .filter-separator {
    min-height: 45px;
    height: 100%;
    border: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);
    margin: 0 20px;
    width: 0;
  }

  .btn-rp-primary-dark {
    min-width: 140px;
    height: 45px;
    padding: 0.6rem 0.9rem;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
  }

  .filter-toggle {
    cursor: pointer;
    background-color: #f5f5f5;
    min-width: 150px;
    height: 45px;
    border-radius: 8px;
    padding: 0.6rem 0.9rem;
  }

  .filter-dropdown {
    position: relative;

    .filter-list {
      position: absolute;
      background-color: $light;
      margin-top: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 320px;
      display: none;

      ul {
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          cursor: pointer;
          padding: 10px;
          transition: all ease 0.3s;

          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
}

.defult-imgs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .img-container {
    width: 138px;

    img {
      border-radius: 6px;
      cursor: pointer;

      &:hover {
        outline: #00a39d solid 4px;
      }
    }
  }

  .selected-img img {
    outline: #00a39d solid 4px;
  }
}

.uploded-img img {
  width: 65px;
  border-radius: 4px;
}

.question-analytics {
  // overflow-y: auto;
  // max-height: 540px;
  // padding: 10px;
  // border-radius: 8px;

  // &.box__shadow-top {
  //   box-shadow: inset 0px 3px 4px -2px rgba(0, 0, 0, 0.25);
  // }

  // &.box__shadow-bottom {
  //   box-shadow: inset 0px -8px 12px -9px rgba(0, 0, 0, 0.25);
  // }

  .questions-graph {
    display: flex;
    gap: 10px;
    align-items: center;
    // margin-bottom: 10px;
    padding: 5px 15px;
    border-radius: 12px;
    border: 1px solid transparent;
    transition: all ease 0.3s;
    cursor: pointer;

    &:hover {
      border: 1px solid #00a39d;
    }

    .progress {
      height: 35px;
      background-color: transparent;

      .progress-bar {
        border-radius: 0;

        &:first-child {
          border-radius: 8px 0 0 8px;
          // border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
          // border-radius: 8px;
        }
      }
    }
  }

  .option .questions-graph.selected {
    border: 1px solid #00a39d;
    background: #e4fcfb;
  }
}

.select-container {
  position: relative;

  &.active {
    .select {
      border-radius: 12px 12px 0 0;
    }

    .option-container {
      height: 240px;
      border: 1px solid #00a39d;
      border-top: 0px;
      border-radius: 0 0 12px 12px;
      padding: 10px;
      padding-bottom: 0;
    }
  }

  .select {
    position: relative;
    min-height: 50px;
    border: 1px solid #00a39d;
    background: #e4fcfb;
    border-radius: 12px;
    display: flex;
    align-items: center;

    .questions-graph:hover {
      border: 1px solid transparent;
    }

    .question-input {
      cursor: pointer;
      width: 100%;
    }
  }

  .option-container {
    position: absolute;
    background: transparent;
    height: 0;
    overflow-y: scroll;
    transition: 0.4s;
    width: 100%;
    z-index: 2;
    background-color: $light;

    .option {
      cursor: pointer;
      margin-bottom: 10px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #a1a1a1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #a8a8a8;
    }
  }
}

.legends-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.legends {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.legends-title {
  color: #7c7c7c;
  margin-left: 5px;
  font-size: 17px;
  font-family: 'Source Sans Pro';
}

.agree-legend .legends {
  background-color: #38857f;
}

.s_agree-legend .legends {
  background-color: #00a39d;
}

.sometimes-legend .legends {
  background-color: #706563;
}

.disagree-legend .legends {
  background-color: #a84643;
}

.s_disagree-legend .legends {
  background-color: #e2231a;
}

.legends-box.slected {
  .legends-title {
    color: #ccc;
  }

  .legends {
    background-color: #ccc;
  }
}

// corporate
.corporate-tabs {
  gap: 25px;
  justify-content: revert;

  .over-tab {
    .btn-rp-secondary {
      min-width: 142.4px;
      // margin-bottom: 1.5rem;
    }
  }
}

// Symptoms tabs
.symptoms-modal {
  max-width: 782px;
}

.symptoms-checkbox {
  background: #ffffff;
  border: 1px solid #00a39d;
  border-radius: 12px;
  padding: 10px 20px;
  width: 330px;
  min-width: 330px;
  cursor: pointer;
  margin-bottom: 30px;
}

.symptoms-tab {
  justify-content: space-between;

  .nav-item {
    .nav-link {
      color: $dark;
      font-size: 14px;
      padding: 12px 33px;
      background-color: rgba($color: $rp-med-gray, $alpha: 0.3);
      border-radius: 31px;

      &.active {
        background-color: #006061;
        color: $light;
      }
    }
  }
}

.nutritionist-table {
  .table tbody tr {
    height: 60px;

    td a {
      font-size: 15px;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.meal-table {
  margin-bottom: 0;

  tbody tr:nth-child(odd) {
    background-color: #f5feff;
  }

  thead tr th {
    &:first-child {
      border-top-left-radius: 12px;
    }

    &:last-child {
      border-top-right-radius: 12px;
    }
  }

  tbody tr {
    &:last-child td:first-child {
      border-bottom-left-radius: 12px;
    }

    &:last-child td:last-child {
      border-bottom-right-radius: 12px;
    }
  }
}

.plan-suggestion-container {
  overflow-y: auto;
  height: 300px;
  border-radius: 12px;

  .meal-table {
    background-color: $light;

    thead {
      position: sticky;
      top: 0;
    }
  }

  @include scrollBar;

  &::-webkit-scrollbar {
    width: 5px;
  }
}

.user-dite-info {
  @include spaceBetween;
  margin-bottom: 1rem;

  .filter-title {
    & span {
      font-weight: 600;
      color: $rp-primary-dark;
    }

    color: $rp-dark-gray;
  }

  .filter-separator {
    margin: 0;
  }
}

.plan_content {
  width: 77%;
}

.modal-body .assessment-tabs-group .btn-assment-month {
  padding: 10px;
  width: 180px;
  margin-right: 0;
  text-align: left;

  span {
    color: $dark;
  }
}

.modal-body .filter-seperator {
  height: 100%;
  width: 1px;
  background-color: $dark;
  opacity: 50%;
}

// .modal-title .dropdown-check-list .anchor{
//   background-color:$light;
// }
// .modal-title .dropdown-check-list ul .items{
//   background-color:$light
// }
// .dropdown-list{
//   width:100%;
//   .anchor{
//     position:relative;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 10px 15px 10px 10px;
//     border-radius: 8px;
//   }
// }

.event-sub-title {
  font-weight: 700;
  font-size: 22px;
  color: $rp-dark-gray;
}

// .event-date {
//   font-weight: 600;
//   margin: 10px 0;
//   font-size: 20px;
//   color: $rp-dark-gray;

//   i {
//     font-size: 20px;
//     color: $rp-dark-gray;
//   }
// }

// .event-discription {
//   font-size: 25px;
//   color: #42526E;
//   line-height: 32px;
//   letter-spacing: 1px;

//   b {
//     color: $rp-dark-gray;
//   }
// }

.event-feedback-stats {
  margin: 2rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  .feedback-question {
    font-weight: 600;
    line-height: 20px;
    color: $rp-dark-gray;
    margin-bottom: 1.5rem;
    letter-spacing: 0.5px;
  }

  .nps-score-pills {
    @include npsPill($light, #22aa58);
  }

  .nps-detractors-pills {
    @include npsPill($dark, #fce2e0);
  }

  .nps-promoters-pills {
    @include npsPill($dark, #d0eee4);
  }

  .stats-container {
    padding: 0 2.1rem;
  }

  .nps-progress-bar {
    position: relative;

    &::after,
    &::before {
      content: '100';
      position: absolute;
      right: -33px;
      bottom: 50%;
      font-size: 15px;
      color: $rp-dark-gray;
      font-weight: 600;
      transform: translateY(50%);
    }

    &::before {
      content: '-100';
      left: -39px;
    }

    .nps-score-indicator {
      background: $dark;
      width: 2px;
      bottom: 50%;
      height: 50px;
      position: absolute;
      transform: translateY(50%);

      span {
        position: absolute;
        top: -25px;
        left: -10px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .progress {
    height: 30px;

    .progress-bar {
      border-radius: unset;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      padding-left: 5px;
      z-index: 1;

      &:first-child {
        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .improvement_bar {
    background-color: #f16e64;
    width: 50%;
  }

  .good_bar {
    background-color: #f9b73e;
    width: 15%;
  }

  .great_bar {
    background-color: #3cc273;
    width: 20%;
  }

  .excellent_bar {
    background-color: #22aa58;
    width: 15%;
  }

  .nps-indicator-container {
    width: 100%;
  }

  .nps-indicators {
    display: flex;
    margin-top: 10px;

    .nps-indicator {
      padding-bottom: 5px;
      font-size: 15px;
      font-weight: 600;
      line-height: 26px;
      border-left: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);
      border-right: 1px solid rgba($color: $rp-med-gray, $alpha: 0.5);
      height: 35px;
      align-items: end;
      justify-content: center;
      display: flex;

      &:first-child {
        border-left: 2px solid rgba($color: $rp-med-gray, $alpha: 0.5);
      }

      &:last-child {
        border-right: 2px solid rgba($color: $rp-med-gray, $alpha: 0.5);
      }
    }

    .first-indi {
      color: #f16e64;
      width: 50%;
      flex: 0 0 auto;
    }

    .second-indi {
      color: #f9b73e;
      width: 15%;
      flex: 1;
    }

    .third-indi {
      width: 20%;
      color: #3cc273;
    }

    .fourth-indi {
      width: 15%;
      color: #3cc273;
    }
  }
}

.NPS-scale {
  color: #42526e;
  font-size: 15px;
  line-height: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}

.event-card {
  border-radius: 8px;
  background: url('../images/placeholder/strenght_insipiration.jpg') no-repeat
    center center/cover;
  position: relative;
  min-height: 284px;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 143, 134, 0.7) 0%,
      rgba(0, 70, 70, 0.7) 100%
    );
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 100%);
  }

  .event-description {
    color: $light;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;

    .event-title {
      color: inherit;
      font-size: 22px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    @include instructor;

    @include eventDate($light);

    @include eventMode($light);

    .nps-scores {
      display: flex;
      align-items: center;
      position: inherit;
      bottom: 15px;

      span {
        font-weight: 500;
        color: $light;
      }

      .nps-score {
        border: 2px solid $light;
        color: $light;
        background-color: #22aa58;
        padding: 5px;
        border-radius: 6px;
        margin: 0 5px;
      }
    }
  }
}

.rp-modal {
  max-width: 850px;

  .modal-content {
    border-radius: 25px;
    overflow: hidden;
    padding: 15px;

    .rp-modal-header {
      @include spaceBetween;
      padding: 1rem;

      .modal-title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.3px;
      }
    }

    .modal-body {
      .nav {
        justify-content: center;

        .nav-item {
          .nav-link {
            min-width: 145px;
            min-height: 50px;
            border-radius: 8px;
            background-color: #f5f5f5;
            color: $dark;
            font-size: 1rem;

            i {
              font-size: 1.4rem;
            }

            &:nth-of-type(1) {
              margin: 0px 5px;
            }

            &.active {
              background-color: $rp-primary-color;
              color: $light;
              font-weight: 600;

              i {
                color: $light;
              }
            }
          }
        }
      }

      .tab-content {
        max-height: 470px;
        overflow-y: scroll;
        @include scrollBar;

        &::-webkit-scrollbar-track {
          background-color: #c6f6f5;
        }

        &::-webkit-scrollbar-thumb {
          background: #006061;
        }

        border-radius: 16px;

        .event-instructor-info {
          background-color: #c6f6f5;
          border-radius: 16px;
          padding: 15px;
          // max-height: 470px;
          // overflow-y: scroll;
          // @include scrollBar;
        }

        @include eventDate($dark);
        @include eventMode($dark);

        .event-description {
          font-size: 17px;
          font-weight: 600;
          line-height: 25px;
          letter-spacing: 0.3px;
          color: $dark;
          margin-bottom: 15px;
        }

        @include instructor;

        .instructor-container {
          display: block;

          .instructor-info {
            margin-bottom: 1rem;
            display: flex;

            .instructor {
              width: 10%;
              height: 10%;
            }

            .instructor-description {
              width: 90%;

              .name {
                font-weight: 700;
                color: $dark;
                letter-spacing: 0.8px;
                font-size: 15px;
                margin-bottom: 5px;
              }

              p {
                letter-spacing: 0.8px;
              }
            }
          }
        }
      }
    }
  }
}

.workshop-event-tabs-group {
  .nav {
    .nav-item {
      @include assesTabs;
      margin-right: 10px;

      .btn-assment-month.active {
        border: 2px solid $rp-primary-color;

        span {
          color: $rp-primary-color;
          font-weight: 500;
        }
      }
    }
  }
}

.btn-assment-month .session-dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  width: 100%;
  left: 0;
  background-color: #f9f9f9;
  text-align: left;
  position: absolute;
  border: 1px solid $rp-primary-color;
  border-radius: 6px;
  padding: 10px;
  height: 200px;
  overflow-y: auto;

  @include scrollBar;
}

.session-dropdown-menu {
  .btn-assment-month {
    background-color: #fff;
    border-radius: 8px;
    height: 65px;
    width: 100% !important;
    border: 1px solid transparent;
    margin-bottom: 5px;

    &.month-active {
      border: 1px solid $rp-primary-color;
    }
  }
}

.btn-assment-month .session-dropdown-menu.fade-down {
  top: 80%;
  transform: rotateX(-75deg);
  transform-origin: 0% 0%;
}

.btn-assment-month .session-dropdown-menu.show-drop {
  transition: 0.5s;
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: rotateX(0deg);
}

@import 'responsive';

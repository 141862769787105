// login page
// applies to small devices
@media (max-width: 767.98px) {
  .login-container {
    padding: 1rem 1rem 0;
  }
}

// applies to medium devices
@media (max-width: 991.98px) {
  .login-bg {
    display: none;
  }

  .login-container {
    padding-top: 1rem;
  }
}

@media (max-width: 400px) {
  .achivment h6 {
    font-size: 20px;
    text-align: center;
  }
  .questionnaire .next-button .btn-rp-primary {
    min-width: 80px;
  }
  .questionnaire .next-button .btn-rp-secondary {
    min-width: 80px;
  }
}

@media (max-width: 768px) {
  .nav-tabs .nav-link {
    font-size: 12px;
  }

  .text-md-center {
    text-align: center;
  }

  .about {
    flex-direction: column;
  }

  .daily-link-style-2 {
    display: block;

    .daily-link-img {
      padding-left: 0;
    }
  }

  .questionnaire {
    padding: 1rem;

    .btn-group {
      display: grid;
    }

    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .btn-group > .btn-group:not(:first-child) > .btn,
    .btn-group > .btn:nth-child(n + 3),
    .btn-group > :not(.btn-check) + .btn {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    // .next-button {
    //   .btn-next {
    //     min-width: 130px;
    //   }
    // }
  }

  .test-progress {
    display: none;
  }

  header,
  section {
    margin-bottom: 2.5rem;
  }

  .header {
    align-items: center;
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .mob-toggler {
    display: block;
  }

  .head-tabs {
    margin-top: unset;

    .anlytic-tab {
      display: none;
    }
  }

  .separator-primary {
    display: none;
  }

  .user-options {
    .client-icon,
    .profile-icon,
    .separator {
      display: none;
    }

    .dropdown-toggler {
      display: none;
    }
  }

  .menu-items {
    right: 0;
  }

  ul.fade-down {
    min-width: unset;
    width: 100vw;
    padding: 0 40px;
    overflow-y: auto;
    max-height: 550px;
  }

  .profile-mob {
    display: block !important;
  }

  .great {
    display: flex;

    h5 {
      margin-bottom: 0;
    }

    p {
      font-size: 13px;
    }
  }

  .next-mnth {
    display: none;
  }

  .section-heading {
    font-size: 23px;
    line-height: 25px;
  }

  .about .section-heading {
    font-size: 33px;
    line-height: 35px;
  }

  .section-sub-heading {
    font-size: 17px;
    line-height: 20px;
  }

  .btn-assment-month {
    min-width: 120px;
  }

  .card-heading-dark {
    font-size: 22px;
    line-height: 24px;
  }

  .about-sub-hed {
    font-size: 15px;
    line-height: 17px;
  }

  .icon i {
    font-size: 29px;
  }

  .about-card .sub-titel {
    font-size: 14px;
  }

  .card-heading-light {
    font-size: 24px;
    line-height: 26px;
  }

  .circle-pill {
    .pill-badge {
      width: 60px;
      height: 60px;
      font-size: 2rem;
    }
  }

  .positive-impact {
    .section-heading {
      font-size: 20px;
      line-height: 22px;
    }

    .icon-list {
      ul {
        padding: 0;
      }

      .li-cnt p {
        font-size: 15px;
      }
    }
  }

  .term_cndtion {
    min-height: 720px;
  }

  .trend-card {
    padding: 1.5rem;

    .trend-button {
      display: none;
    }
  }

  .trend-card:nth-child(2n + 2) {
    flex-direction: column-reverse;
  }

  .trend-card:nth-child(2n + 1) > div {
    padding-left: 0;
  }

  .products {
    flex-direction: column;
  }

  .prodduct-thumbnail {
    margin-left: 0;
    flex-direction: row;
    height: unset;
    overflow: unset;

    img {
      width: 15%;
    }
  }

  .mob-menu {
    h5 {
      font-size: 18px;
      font-weight: normal;
      line-height: 16px;
      color: $rp-primary-color;
    }

    .user-id {
      font-size: 12px;
      margin-bottom: 0;
    }

    .nav-ioc {
      .dot {
        left: 74%;
      }
    }

    .fixed-bottom {
      left: 10%;
      right: 10%;
      bottom: 5%;
    }

    .mob-menu-footer {
      .copy {
        font-family: $montserrat;
        font-weight: 400;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: #666666;
      }

      .company-logo {
        justify-content: center;

        .separator {
          margin: 0 20px;
        }
      }
    }
  }

  .plan-tabs {
    .btn-options {
      min-width: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px !important;
      margin-right: 5px;
    }
  }

  .sidenav {
    position: unset;
    flex-direction: unset;
    justify-content: center;

    .lang-btn {
      margin-right: 10px;
      border-radius: 5px;
    }
  }

  .country_code_div {
    label {
      margin-bottom: 22px;
    }
  }

  .auth-massage_div {
    display: unset;

    .auth-massage {
      display: block;
    }
  }

  .result_dot_style {
    height: 20%;
    width: 60%;
  }

  .dosage-action {
    width: 320px;
  }

  .auth-btn {
    margin-left: unset;
  }

  .notification-popup {
    right: -38px;
    width: 100vw;
  }

  .profile-edit {
    .edit-profile-actions {
      display: flex;
      justify-content: space-between;

      .btn-rp-primary,
      .btn-rp-primary-red {
        min-width: 120px;
      }
    }
  }

  .btn-small {
    min-width: unset;
  }

  .supplement-container:first-child {
    margin-top: 1rem;
  }

  .supplement-container {
    margin-bottom: 1rem;
    display: block;
  }

  // .spacer {
  //   padding: 3rem;
  //   margin: 3rem;
  // }

  // paln page
  .main_content {
    width: 100%;
    padding: 0 15px;
  }

  .side_nav {
    animation: fadeOutLeft;
    animation-delay: 1s;
    animation-duration: 0.5s;
    display: none;
    transform: translateX(-100%);
    // transform 0.3s ease-in-out;
    transition: visibility 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .mobile-offcanvas {
    // visibility: visible;
    display: block;
    transform: translateX(0);
    animation: fadeInLeft;
    animation-duration: 0.5s;
    position: absolute;
    z-index: 44;
    background: #f7f7f7;
    width: 85% !important;
    box-shadow: rgba($color: #000000, $alpha: 0.24) 0px 3px 8px;
  }

  .plan-table {
    .table {
      width: 650px;
    }
  }

  .recipe-section {
    &:nth-child(even) .recipe-col,
    .recipe-col {
      flex-direction: column-reverse;
    }
  }

  .workshop-btn .btn-rp-primary {
    margin-bottom: 10px;
  }
  .head-tabs .head-tab {
    margin-bottom: 10px;
    min-width: 130px;
  }

  .head-tabs .head-tab a {
    min-width: 130px;
  }
  .confirmation-footer .btn-rp-primary-red {
    border: none;
  }
  .symptoms-tab .nav-item .nav-link {
    margin-top: 10px;
  }
  .symptoms-tab {
    justify-content: center;
  }

  // .nav-item {
  //   display: block;
  //   flex-wrap: wrap;
  // }
}

// // height media querry
// @media (max-height: 415px) {
//   .spacer {
//     padding: 2rem;
//     margin: 2rem;
//   }
// }

// // tab media querry ipad-air

// @media (max-width: 820px) {
//   .spacer {
//     padding: 5rem;
//     margin: 5rem;
//   }
// }

@media (min-width: 992px) {
  .container {
    padding: 0;
  }

  .fixed-bottom {
    left: 10%;
    right: 10%;
    position: absolute;
  }

  .modal-width {
    max-width: 680px;
  }

  .login-container .fixed-to-bottom {
    left: 10%;
    right: 10%;
    position: absolute;
    bottom: 0;
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  .login-container {
    .fixed-to-bottom {
      position: static;
    }
  }
}

@media (max-width: 360px) {
  .badge {
    font-size: 10px;
  }

  ul.fade-down {
    padding: 0 36px;
  }

  .dropdown-item {
    font-size: 0.75rem;
  }

  .btn-rp-primary {
    height: auto;
  }
  .modal-footer > * {
    margin-right: 0;
  }
}

@media (min-width: 769px) and (max-width: 1199px) {
  .plan_content {
    width: 100%;
  }
  .side_nav {
    display: none;
  }
  .mobile-offcanvas {
    // visibility: visible;
    display: block;
    transform: translateX(0);
    animation: fadeInLeft;
    animation-duration: 0.5s;
    position: absolute;
    z-index: 44;
    background: #f7f7f7;
    width: 40% !important;
    box-shadow: rgba($color: #000000, $alpha: 0.24) 0px 3px 8px;
  }
}
@media (max-width: 500px) {
  .download-button .btn-rp-primary {
    margin-top: 10px;
  }
}
@media (max-width: 600px) {
  .supplement-button .btn-rp-primary {
    margin-top: 10px;
  }
  .symptoms-checkbox {
    min-width: auto;
  }
}

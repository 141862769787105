@font-face {
    font-family: "flaticon_rp-icons";
    src: url("./flaticon_rp-icons.ttf?d67e2b83a61210dc9045bec1fd0b35ef") format("truetype")
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_rp-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-search:before {
    content: "\f101";
}
.flaticon-group:before {
    content: "\f102";
}
.flaticon-people-resiliency:before {
    content: "\f103";
}
.flaticon-dashboard:before {
    content: "\f104";
}
.flaticon-building:before {
    content: "\f105";
}
.flaticon-user:before {
    content: "\f106";
}
.flaticon-customer:before {
    content: "\f107";
}
.flaticon-placeholder:before {
    content: "\f108";
}
.flaticon-building-1:before {
    content: "\f109";
}
.flaticon-like:before {
    content: "\f10a";
}
.flaticon-link:before {
    content: "\f10b";
}
.flaticon-daily-health-app:before {
    content: "\f10c";
}
.flaticon-supplement:before {
    content: "\f10d";
}
.flaticon-mindfulness:before {
    content: "\f10e";
}
.flaticon-heart:before {
    content: "\f10f";
}
.flaticon-filter:before {
    content: "\f110";
}
.flaticon-profile:before {
    content: "\f111";
}
.flaticon-action:before {
    content: "\f112";
}
.flaticon-assessment-complete:before {
    content: "\f113";
}
.flaticon-emp-id:before {
    content: "\f114";
}
.flaticon-group-1:before {
    content: "\f115";
}
.flaticon-location:before {
    content: "\f116";
}
.flaticon-name:before {
    content: "\f117";
}
.flaticon-download:before {
    content: "\f118";
}
.flaticon-send:before {
    content: "\f119";
}
.flaticon-trash:before {
    content: "\f11a";
}
.flaticon-pencil-outline:before {
    content: "\f11b";
}
.flaticon-notification:before {
    content: "\f11c";
}
.flaticon-height:before {
    content: "\f11d";
}
.flaticon-weighing-scale:before {
    content: "\f11e";
}
.flaticon-bmi:before {
    content: "\f11f";
}
.flaticon-allergic:before {
    content: "\f120";
}
.flaticon-bar-graph:before {
    content: "\f121";
}
.flaticon-calorie:before {
    content: "\f122";
}
.flaticon-date-of-birth:before {
    content: "\f123";
}
.flaticon-gender:before {
    content: "\f124";
}
.flaticon-healthy-heart:before {
    content: "\f125";
}
.flaticon-conference:before {
    content: "\f126";
}
.flaticon-yoga:before {
    content: "\f127";
}

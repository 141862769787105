button {
  border: none;
  background: none;
}

.btn {
  font-size: 15px;
  transition: all ease 0.3s;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.3px;
  font-weight: 500;
  outline: none;
  // color: $rp-primary-dark;

  // &:hover {
  //   color: red;
  // }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: $rp-primary-color;
    color: $light;

    i {
      color: $light;
    }
  }

  .active:hover {
    background-color: #11928a;
  }

  &-login {
    width: 100%;
    color: #fff;
    font-weight: 700;
    padding: 10px 24px;
    background: #00a89d;
    border-radius: 6px;

    &:hover {
      color: #fff;
      background: #01948a;
    }
  }

  // &-assment-month {
  //   min-width: 158px;
  //   font-family: $montserrat;
  // }
  // &-assment-month,
  &-card-tab {
    background-color: $light;
    border-radius: 6px 6px 0 0;
    color: $rp-primary-color;
    margin-right: 0.25rem;

    span {
      color: $rp-primary-color;
      font-weight: 300;
    }

    &:hover {
      background-color: $rp-primary-color;
      color: $light;

      span {
        color: $light;
      }
    }
  }

  &-options {
    background: rgba(212, 219, 218, 0.3);
    border-radius: 6px;
    letter-spacing: 0.3px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    color: #006060;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:hover {
      color: #006060;
      background-color: #d4dbda;
      border-radius: 6px !important;
    }

    &:visited {
      box-shadow: none;
      outline: none;
    }
  }

  &-group.plan-tabs {
    background: rgba(212, 219, 218, 0.3);
    border-radius: 6px;
    width: 100%;
  }

  &-rp-primary,
  &-rp-primary-dark,
  &-rp-primary-red {
    @include primaryButtons($rp-primary-color, $light);

    i {
      color: $light;
      padding-top: 3px;
    }

    &:hover {
      color: $light;
    }
  }

  &-rp-primary {
    &:active {
      background-color: rgba($color: $rp-primary-color, $alpha: 0.2);
      color: $rp-primary-color;

      i {
        color: $rp-primary-color;
      }
    }
  }

  &-rp-primary-red {
    @include primaryButtons($rp-primary-red, $light);
    // background-color: $rp-primary-red;

    &:active {
      background-color: rgba($color: $rp-primary-red, $alpha: 0.2);
      color: $rp-primary-red;

      i {
        color: $rp-primary-red;
      }
    }
  }

  &-rp-primary-dark {
    @include primaryButtons($rp-primary-dark, $light);

    &:active {
      background-color: rgba($color: $rp-primary-dark, $alpha: 0.2);
      color: $rp-primary-dark;

      i {
        color: $rp-primary-dark;
      }
    }
  }

  &-play {
    position: absolute;
    bottom: 2rem;
  }

  &-shop {
    background-color: $rp-primary-color;
    padding: 10px 30px;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 0.4px;
    line-height: 20px;
    font-weight: 700;

    &:hover {
      color: $light !important;
    }
  }

  &-flip-light {
    i {
      color: $light;
    }
  }

  &-flip,
  &-flip-light {
    background-color: #f4f4f4;
    line-height: 0;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px 6px;

    i {
      color: $rp-primary-color;
    }
  }

  &-rp-secondary {
    @include primaryButtons($light, $rp-primary-color);
    border: 1px solid $rp-primary-color;

    &:hover {
      background-color: $rp-primary-color;
      color: $light;
    }

    i {
      color: $rp-primary-color;
    }

    &:hover i {
      color: $light;
    }
  }

  // &-ribbon {
  //   color: $light;
  //   cursor: pointer;
  //   font-size: 14px;
  //   font-weight: 600;
  //   letter-spacing: 0.5px;
  //   padding-right: 20px;
  //   font-family: $montserrat;

  //   &:hover {
  //     border-color: $light;
  //     color: $light;
  //   }
  // }
}

// assment-button
// .btn-assment-month.active,
// .btn-assment-month.month-active,
// .next-mnth {
//   background-color: $rp-primary-color;
//   color: $light;
//   span {
//     display: flex;
//     justify-content: center;
//     color: $light;
//     font-family: $montserrat;
//     font-weight: 300;
//   }
// }
// .prev-mnth {
//   // background-color: #d8eceb;
//   // color: $rp-primary-color;
//   border: 1px solid $rp-primary-color;
// }
// .next-mnth {
//   background-color: #f5f5f5;
//   border-color: $rp-primary-color;
//   color: $rp-primary-color;
//   margin-right: 0.25rem;
//   span {
//     color: $rp-primary-color;
//   }
//   &:hover {
//     background-color: $rp-primary-color;
//     color: $light;
//   }
// }
.btn-postion-right {
  position: absolute;
  width: 100%;
  right: 4rem;
  bottom: 4rem;
}

.active-opt {
  color: #006060;
  background-color: #d4dbda;
  border-radius: 6px !important;
  font-weight: 700;
}

.btn-group.plan-tabs {
  .btn-options {
    height: 40px;
    padding: 0.6rem 0;
  }
}

input[type='radio']:checked + label {
  background-color: #d4dbda;
  box-shadow: none !important;
  border-radius: 6px !important;
}

.btn-close:focus {
  box-shadow: none;
}

.btn-sm,
.btn-full {
  min-width: unset;
  height: unset;
  padding: 5px 10px;
  font-weight: normal;
  font-size: 14px;
}

.btn-full {
  width: 100%;
}

.active-btn {
  background-color: $rp-primary-color;
  color: $light;
}

.btn-rp-light {
  @include primaryButtons($light, $dark);
  border: 1px solid $dark;
  // background-color: $light;
  // border-radius: 8px;
  // color: $dark;
  // margin-right: 15px;
  // min-height: 39px;
  // max-height: 39px;
  // font-size: 14px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $dark;
  }

  &:active {
    background-color: $light;
  }

  i {
    color: $dark !important;
    margin-top: 4px;

    &:active {
      color: $dark !important;
    }
  }
}

.compare-btn {
  min-width: 140px;
  height: 60px;
}
.close-compare-btn {
  background-color: $light;
  border: 2px solid $rp-primary-red;
  color: $rp-primary-red;
  min-width: 140px;
  height: 60px;
  font-size: 1rem;
  border-radius: 8px;
  font-weight: 500;
}

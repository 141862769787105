body {
  font-size: 16px;
}
.login-bg {
  position: fixed;
}
.login-container {
  position: absolute;
  right: 0;
}

.invalid {
  border: 2px solid #c33a3a !important;
  /* background-color: #fddddd !important; */
}

.ant-picker-input > input::placeholder {
  color: black;
}

.tabsActive {
  color: darkgray;
  font-size: 1rem;
}

.thmbnlActive {
  opacity: 0.4;
}

input[type='range'] {
  /* -webkit-appearance: none; */
  appearance: none;
  width: 55%;
  margin-left: 25%;
  height: 5px;
  /* background: rgba(253, 249, 249, 0.6); */
  background: white;
  border-radius: 5px;
  background-image: linear-gradient(#00a39d, #00a39d);
  background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
  appearance: none;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  background: #00a39d;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

/* input[type="range"]:hover::-webkit-slider-thumb { */
input[type='range']:hover::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 4px rgba(4, 134, 143, 0.49);
}

input[type='range']::-webkit-slider-runnable-track {
  appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #00a39d;
  border-color: #00a39d transparent #00a39d transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.centered {
  margin: 3rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-footer.fixed-bottom {
  left: 0;
  right: 0;
}

.head-tabs .head-tab {
  font-size: 1rem;
}

.asterisk:after {
  content: ' *';
  color: #dc3545;
}

.ant-picker-focused {
  border-color: #00a39d;
}
.ant-picker:hover {
  border-color: unset;
}
.ant-picker-header-view button:hover {
  color: #00a39d;
}
.loading-indicator:before {
  content: '';
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  position: fixed;
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: none;
}

/* .fade:before {
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
} */

.video-main {
  min-height: 450px;
}

.ant-picker-dropdown {
  z-index: 9999;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #00a39d;
}

.user-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
}

.designation {
  margin-left: 0;
}

.slick-prev:before {
  background-image: unset;
  content: '\f104';
  font-family: 'FontAwesome';
  width: unset;
  height: unset;
  color: #006060;
  font-size: 20px;
  font-weight: 700;
}

.slick-next:before {
  background-image: unset;
  content: '\f105';
  font-family: 'FontAwesome';
  width: unset;
  height: unset;
  color: #006060;
  font-size: 20px;
  font-weight: 700;
}
.music-card,
.video-card {
  border-radius: 12px 12px 0px 0px;
}
.prodduct-thumbnail {
  height: 430px;
  justify-content: center;
}

.prodduct-thumbnail .slick-prev {
  top: -20px;
  left: 35px;
}
.prodduct-thumbnail .slick-next {
  right: 35px;
  top: 365px;
}
.prodduct-thumbnail .slick-prev:before {
  background-image: unset;
  content: '\f106';
  font-family: 'FontAwesome';
  width: unset;
  height: unset;
  color: #006060;
  font-size: 20px;
  font-weight: 700;
}

.prodduct-thumbnail .slick-next:before {
  background-image: unset;
  content: '\f107';
  font-family: 'FontAwesome';
  width: unset;
  height: unset;
  color: #006060;
  font-size: 20px;
  font-weight: 700;
}

section.row.mt-3.justify-content-between {
  margin-bottom: 2rem !important;
}

.dropdown-item i {
  vertical-align: baseline;
  width: 20px;
}
.dropdown-item {
  display: flex;
  align-items: center;
}

.plan-tabs .group-item.btn-options {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}

.plan-group .group-items .group-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}

ol li {
  font-size: 15px;
  line-height: 18px;
  font-variant-numeric: tabular-nums;
}

.modified-ol li {
  list-style: lower-alpha;
  margin-bottom: 16px;
}

.upper-alpha li {
  list-style: upper-alpha;
}

.vimeo-full-width {
  padding: 56.25% 0 0 0;
  position: relative;
}

.vimeo-full-width iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 99.97%;
  height: 100%;
}

.container_2 {
  max-width: 1300px !important;
}

.border-right th {
  border-right: 1px solid;
}

.dots {
  background-color: transparent;
  cursor: default;
}

.popover-body {
  box-shadow: 5px 10px 21px rgba(0, 0, 0, 0.17);
}
.popover {
  border: unset;
}

.rp-slider .slick-next {
  right: 3px;
  z-index: 1;
}

.rp-slider .slick-prev {
  left: 3px;
  z-index: 1;
}
.supplemnet-container .scene--card {
  min-height: 426px;
}
.unauth-daily-link {
  width: unset;
  margin: auto;
  position: relative;
  padding-top: 20px;
}

.unauth-daily-link div:last-child {
  position: relative;
  right: 11px;
}

.unauth-desc {
  min-height: 120px;
}

@media (max-width: 768px) {
  .supplement-container {
    display: block;
  }
  .prodduct-thumbnail {
    /* margin-left: 15px; */
    height: unset;
    flex-direction: column;
    /* padding-left: 15px; */
    padding-right: 0px;
  }
  .prodduct-thumbnail .slick-prev {
    top: 28px;
    left: -20px;
    z-index: 99;
  }
  .prodduct-thumbnail img {
    width: 70%;
  }
  .prodduct-thumbnail .slick-prev:before {
    background-image: unset;
    content: '\f104';
  }

  .prodduct-thumbnail .slick-next:before {
    background-image: unset;
    content: '\f105';
  }
  .prodduct-thumbnail .slick-next {
    right: 0px;
    top: 28px;
    z-index: 99;
  }
  .unauth-daily-link {
    right: unset;
    width: 90%;
  }
}
.plan-slider .slick-next {
  right: -20px;
  z-index: 99;
}

.plan-slider .slick-prev {
  left: -20px;
  z-index: 99;
}

.video-big {
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0px -4px 20px rgb(0 0 0 / 4%), 0px 4px 4px rgb(0, 0, 0, 0.1);
}

.anticon-exclamation-circle {
  display: none;
}

.antdButton .ant-popover-message-title {
  padding-left: 0;
}

.antdButton .ant-popover-buttons {
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
}

.antdButton .ant-btn-sm {
  background-color: #00a39d;
  border-color: #00a39d;
}

.antdButton .ant-btn-sm span {
  color: #fff;
}

.antdButton .ant-btn-sm:hover {
  border-color: #00a39d;
}

.antdButton .ant-btn-primary {
  background-color: #e02726;
  border-color: #e02726;
}

.antdButton .ant-btn-primary span {
  color: #fff;
}

.antdButton .ant-btn-primary:hover {
  border-color: #e02726;
}

.ant-popover-buttons button:not(:last-child) {
  margin-left: 8px;
}

.Toastify__toast--success svg {
  fill: #00a39d;
}

.Toastify__progress-bar--success {
  background: #00a39d;
}

.antdReminderButton .ant-popover-message-title {
  padding-left: 0;
}

.antdReminderButton .ant-popover-buttons {
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
}

.antdReminderButton .ant-btn-sm {
  background-color: #e02726;
  border-color: #e02726;
}

.antdReminderButton .ant-btn-sm span {
  color: #fff;
}

.antdReminderButton .ant-btn-sm:hover {
  border-color: #e02726;
}

.antdReminderButton .ant-btn-primary {
  background-color: #00a39d;
  border-color: #00a39d;
}

.antdReminderButton .ant-btn-primary span {
  color: #fff;
}

.antdReminderButton .ant-btn-primary:hover {
  border-color: #00a39d;
}

.assmt-heading {
  font-size: 48px;
  letter-spacing: 1.3px;
  line-height: 58px;
}

.spinner-border-sm {
  height: 14px;
  width: 14px;
}

.menu-items {
  overflow-y: auto;
  max-height: 690px;
}

.menu-items::-webkit-scrollbar {
  width: 10px;
}
.menu-items::-webkit-scrollbar-track {
  background: #f8f8f8;
  border-radius: 10px;
}
.menu-items::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.menu-items::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
.disabled {
  pointer-events: none;
  opacity: 0.65;
}

.intro-video .modal-content {
  padding-bottom: 0;
}

[data-testid='otp-input-root'] {
  justify-content: space-between;
}

.is_danger input {
  border-color: red;
}

.active-assmt-card {
  padding: 15px;
  width: 46%;
  border-radius: 6px;
  background-color: #d8eceb;
  margin: 10px;
}

.active-assmt-card .active-assmt-users {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #42526e;
  margin-bottom: 15px;
}

.active-assmt-card .active-assmt-no {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  color: #00a39d;
}

.people-page-2 {
  border-spacing: 0 10px;
  border-collapse: separate;
  font-size: 15px;
}

.people-page-2 thead tr th {
  border-right: 2px solid #fff;
}
.people-page-2 tbody tr {
  height: 50px;
  border-radius: 12px;
  background-color: #fff;
}
.people-page-2 tbody tr td {
  border-bottom: none;
}
.people-page-2 tbody tr td:first-child {
  border-radius: 12px 0 0 12px;
  font-weight: 600;
}
.people-page-2 tbody tr td:last-child {
  border-radius: 0 12px 12px 0;
}

.plan_list ul:not(.list-decimal) li {
  color: #42526e;
  font-size: 15px;
  list-style: disc;
}

/* .scan-download {
  width: 45%;
}
.scaner-title {
  text-align: center;
}

/* .select__option.select__option--is-focused.css-1n7v3ny-option {
  background-color: #fff;
}
.select__option.select__option--is-selected.css-9gakcf-option {
  background-color: transparent;
}
.select__option.select__option--is-focused.select__option--is-selected.css-9gakcf-option {
  background-color: transparent;
} */

.select__option .form-check-label {
  display: inline;
}

.basic-multi-select {
  margin-top: 5px;
}
.css-1rhbuit-multiValue.css-1rhbuit-multiValue {
  background-color: unset;
  border-radius: 0.25rem;
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  background-color: #fff;
  /* margin: 0 4px 4px 0; */
}
.select__multi-value__remove {
  padding: 0;
}
.select__multi-value__remove svg {
  fill: #a5a2a2;
}
.css-eseync-multiValue.select__multi-value {
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 3px 6px;
}
.css-eseync-multiValue.select__multi-value span {
  color: #00a39d;
  font-size: 12px;
  font-weight: normal;
}
.select__control.css-1s2u09g-control {
  border: none;
}
/* .select__control.select__control--is-focused.select__control--menu-is-open.css-1pahdxg-control {
  border: unset;
  border-color: unset;
  outline: none;
  box-shadow: none;
} */

/* .select__control.select__control--is-focused.css-1pahdxg-control:hover {
  border: none;
  box-shadow: none;
} */
.select__control.select__control--is-focused.css-7boxnb-control {
  border: unset;
  box-shadow: 0 0 0 1px #00a39d;
  border-radius: 3px;
  /* padding: 5px 5px 0; */
}
.select__indicator-separator {
  display: none;
}
.select__menu {
  z-index: 2 !important;
  border-radius: 6px !important;
}
.select__value-container.select__value-container--is-multi.css-319lph-ValueContainer,
.select__value-container.select__value-container--is-multi.select__value-container--has-value.css-g1d714-ValueContainer {
  padding: 0;
}
.select__input-container {
  margin: 0;
}
/* .select__indicator.select__dropdown-indicator.css-tlfecz-indicatorContainer {
  position: absolute;
  top: -34px;
  left: 75px;
} */

.location-input-checkbox input {
  border: none;
  background-color: transparent;
}

.location-input-checkbox input:focus-visible {
  outline: none;
}

.rct-checkbox {
  display: none;
}

.label-clear {
  color: #a5a2a2;
  margin-left: 5px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -8px;
  color: rgb(255, 255, 255);
  background: rgba(224, 39, 38, 0.8);
  transition: all ease 0.3s;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding-bottom: 3px;
  right: -10px;
  visibility: hidden;
  cursor: pointer;
}

.badge-2:hover .label-clear {
  visibility: visible !important;
}
.label-clear:hover {
  visibility: visible !important;
}

.rct-node-leaf,
.rct-node-parent {
  margin-bottom: 10px;
  /* padding: 10px 5px; */
}

.rct-node-leaf:last-child,
.rct-node-parent:last-child {
  margin-bottom: 0px;
  /* padding: 10px 5px; */
}

.react-checkbox-tree {
  margin-top: 10px;
  padding: 10px 0;
}

.rct-node-clickable:hover,
.rct-node-clickable:focus {
  background: none;
}

.rct-node.rct-node-parent.rct-node-expanded.activeFilter ol {
  margin-top: 10px;
}

.rct-node-clickable {
  padding: 5px;
  border-radius: 6px;
}

/* .rct-node-clickable:focus {
  background: rgba(216, 236, 235, 1);
} */
.company__control {
  border-radius: 6px !important;
}
.company__control.company__control--is-focused.company__control--menu-is-open {
  border: 1px solid #000;
}

.company__option {
  cursor: pointer !important;
}

.company__option.company__option--is-focused.company__option--is-selected,
.company__option.company__option--is-selected {
  background-color: #00a39d;
}
.company__option.company__option--is-focused {
  background-color: #d8eceb;
}
.company__menu {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  z-index: 2 !important;
}
.company__menu-list {
  padding: 0 !important;
  border-radius: 8px !important;
}
/* .company__option.company__option--is-focused.company__option--is-selected{
  background-color: #00a39d;
} */

/* .custom-dropdown-select .company__control {
  border-color: #ced4da;
  height: 48px;
} */

.custom-dropdown-select .company__control.css-3wsfzh-control {
  border-radius: 8px;
  height: 48px;
  color: #212529;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(33, 37, 41, 0.06),
    0px 0px 1px rgba(33, 37, 41, 0.08);
  font-family: 'montserrat', sans-serif;

  /* &::placeholder {
    color: #212529;
  } */
}

.custom-dropdown-select
  .company__control.css-3wsfzh-control
  .company__placeholder.css-1jqq78o-placeholder,
.company__control.company__control--is-focused .company__placeholder {
  color: #212529 !important;
}

.custom-dropdown-select .company__control.company__control--is-focused {
  border-color: #00a39d;
  box-shadow: none;
  height: 48px;
}

.custom-dropdown-select .company__indicator.company__dropdown-indicator svg {
  fill: #212529 !important;
  /* stroke-width: -1; */
  stroke: #212529 !important;
}

.custom-dropdown-select.select_error .company__control.css-3wsfzh-control {
  border: 1px solid #dc3545;
}

.activeFilter .rct-text {
  background-color: #d9f6f6;
  border-radius: 6px;
  padding: 2px;
}

.nonActiveFilter .rct-text {
  background-color: transparent;
}

.nonActiveFilter ol .activeFilter .rct-text {
  background-color: #d9f6f6 !important;
  border-radius: 6px;
  padding: 2px;
}

.scrollableTree::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.scrollableTree::-webkit-scrollbar {
  width: 16px;
}

.scrollableTree {
  overflow-y: auto;
  position: sticky;
  max-height: 300px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin-top: 20px;
}

.plan-table .table > tbody > tr:first-child {
  height: 0px !important;
}

.action-btns {
  min-height: 37px;
  padding-top: 8px;
}

.btn-space {
  margin-bottom: 3px;
}

.custom-dropdown.active .option-container {
  height: unset;
  min-height: 100px;
}

.session-modal .modal-content {
  border-radius: 24px;
}

.report-conatiner .main-title {
  font-size: 43px;
  line-height: 49px;
  letter-spacing: 1.3px;
  font-weight: 700;
}
.report-conatiner .severe-color {
  color: #e2231a;
}
.report-conatiner .chronic-color {
  color: #a84643;
}
.report-conatiner .moderate-color {
  color: #706563;
}
.report-conatiner .mild-color {
  color: #38857f;
}
.report-conatiner .participation-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.report-conatiner .participation-card {
  background: #fff;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 15px;
  width: 30%;
}
.report-conatiner .participation-card .participation-dot {
  background: url('../images/dot-bottom-right.svg') no-repeat center
    center/cover;
  height: 190px;
  width: 180px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.report-conatiner .participation-card .card-sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #42526e;
}
.report-conatiner .participation-card .card-title {
  font-weight: 500;
  font-size: 48px;
  line-height: 59px;
  color: #00a39d;
  margin-bottom: 1.5rem;
}
.report-conatiner .main-sub-title {
  font-weight: 600;
  font-size: 32px;
  line-height: 25px;
  color: #006061;
  margin-bottom: 10px;
}
.report-conatiner .sub-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #00a39d;
  margin: 10px 20px;
  text-decoration: underline;
}
.report-conatiner .summary ul li {
  margin-bottom: 10px;
  font-size: 20px;
}
.report-conatiner .summary ul li span {
  color: #00a39d;
  font-weight: 600;
}
.report-conatiner .cnt-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}
.report-conatiner .bullet-point {
  background: url('../images/mini-dot-pointer.png') no-repeat center
    center/cover;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-conatiner .bullet-point span {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a39d;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.report-conatiner .cnt-wrapper .cnt {
  color: #006061;
  font-weight: 600;
  font-size: 20px;
}
.report-conatiner .cost-card-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}
.report-conatiner .cost-card,
.report-conatiner .cost-card-mini {
  /* width: 30%;
  */
  border-radius: 16px;
  background-color: #d4fffe;
  padding: 20px;
}
.report-conatiner .cost-card-mini {
  width: 65%;
}
.report-conatiner .card-date {
  margin-bottom: 20px;
}
.report-conatiner .total-cost {
  font-size: 24px;
  font-weight: 700;
  line-height: 37px;
  color: #006061;
}
.report-conatiner .note-section {
  color: #006061;
}
.report-conatiner .note-section ul li {
  margin-bottom: 15px;
  font-size: 18px;
}
.report-conatiner .total-cost span {
  font-size: 56px;
  line-height: 62px;
  color: #006061;
  margin-right: 5px;
  font-weight: 700;
}
.report-conatiner .cost-card-icon i {
  font-size: 3rem;
  color: #006061;
}
.report-conatiner .cost-card-container-title {
  color: #006061;
}

.event-feedback-stats .stats-container {
  padding: 0 2.7rem;
}

/* .custom-dropdown-select {
  border-color: #ced4da;
  height: 48px;
} */

/* custom select css */

/* CustomSelect.css */
.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.custom-select .dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  display: flex;
  flex-direction: column;
}

.custom-select .dropdown-content div {
  padding: 12px;
  cursor: pointer;
}

.custom-select .dropdown-content div:hover {
  background-color: #f0f0f0;
}
.react-datepicker-wrapper{
  width: 100%;
}